/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  CommentRequest,
  CreateTicketRequest,
  HttpError,
  TicketDetailedResponse,
  TicketsResponse,
} from '../models/index';
import {
  CommentRequestFromJSON,
  CommentRequestToJSON,
  CreateTicketRequestFromJSON,
  CreateTicketRequestToJSON,
  HttpErrorFromJSON,
  HttpErrorToJSON,
  TicketDetailedResponseFromJSON,
  TicketDetailedResponseToJSON,
  TicketsResponseFromJSON,
  TicketsResponseToJSON,
} from '../models/index';
import * as runtime from '../runtime';

export interface AddCommentToTicketRequest {
  userId: string;
  ticketId: string;
  commentRequest: CommentRequest;
}

export interface CreateTicketOperationRequest {
  userId: string;
  createTicketRequest: CreateTicketRequest;
}

export interface GetTicketRequest {
  userId: string;
  ticketId: string;
}

export interface GetTicketsRequest {
  userId: string;
}

/**
 *
 */
export class TicketsApi extends runtime.BaseAPI {
  /**
   * add comment
   */
  async addCommentToTicketRaw(
    requestParameters: AddCommentToTicketRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling addCommentToTicket().',
      );
    }

    if (requestParameters['ticketId'] == null) {
      throw new runtime.RequiredError(
        'ticketId',
        'Required parameter "ticketId" was null or undefined when calling addCommentToTicket().',
      );
    }

    if (requestParameters['commentRequest'] == null) {
      throw new runtime.RequiredError(
        'commentRequest',
        'Required parameter "commentRequest" was null or undefined when calling addCommentToTicket().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/tickets/{ticketId}/comments`
          .replace(
            `{${'userId'}}`,
            encodeURIComponent(String(requestParameters['userId'])),
          )
          .replace(
            `{${'ticketId'}}`,
            encodeURIComponent(String(requestParameters['ticketId'])),
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CommentRequestToJSON(requestParameters['commentRequest']),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * add comment
   */
  async addCommentToTicket(
    requestParameters: AddCommentToTicketRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.addCommentToTicketRaw(requestParameters, initOverrides);
  }

  /**
   * create ticket
   */
  async createTicketRaw(
    requestParameters: CreateTicketOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling createTicket().',
      );
    }

    if (requestParameters['createTicketRequest'] == null) {
      throw new runtime.RequiredError(
        'createTicketRequest',
        'Required parameter "createTicketRequest" was null or undefined when calling createTicket().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/tickets`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters['userId'])),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateTicketRequestToJSON(
          requestParameters['createTicketRequest'],
        ),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * create ticket
   */
  async createTicket(
    requestParameters: CreateTicketOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.createTicketRaw(requestParameters, initOverrides);
  }

  /**
   * get ticket with details
   */
  async getTicketRaw(
    requestParameters: GetTicketRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<TicketDetailedResponse>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling getTicket().',
      );
    }

    if (requestParameters['ticketId'] == null) {
      throw new runtime.RequiredError(
        'ticketId',
        'Required parameter "ticketId" was null or undefined when calling getTicket().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/tickets/{ticketId}`
          .replace(
            `{${'userId'}}`,
            encodeURIComponent(String(requestParameters['userId'])),
          )
          .replace(
            `{${'ticketId'}}`,
            encodeURIComponent(String(requestParameters['ticketId'])),
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TicketDetailedResponseFromJSON(jsonValue),
    );
  }

  /**
   * get ticket with details
   */
  async getTicket(
    requestParameters: GetTicketRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<TicketDetailedResponse> {
    const response = await this.getTicketRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get tickets from user
   */
  async getTicketsRaw(
    requestParameters: GetTicketsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<TicketsResponse>>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling getTickets().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/tickets`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters['userId'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(TicketsResponseFromJSON),
    );
  }

  /**
   * Get tickets from user
   */
  async getTickets(
    requestParameters: GetTicketsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<TicketsResponse>> {
    const response = await this.getTicketsRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
