/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { Comment } from './Comment';
import {
  CommentFromJSON,
  CommentFromJSONTyped,
  CommentToJSON,
  CommentToJSONTyped,
} from './Comment';

/**
 *
 * @export
 * @interface CommentsResponse
 */
export interface CommentsResponse {
  /**
   *
   * @type {Array<Comment>}
   * @memberof CommentsResponse
   */
  comments?: Array<Comment>;
}

/**
 * Check if a given object implements the CommentsResponse interface.
 */
export function instanceOfCommentsResponse(
  value: object,
): value is CommentsResponse {
  return true;
}

export function CommentsResponseFromJSON(json: any): CommentsResponse {
  return CommentsResponseFromJSONTyped(json, false);
}

export function CommentsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CommentsResponse {
  if (json == null) {
    return json;
  }
  return {
    comments:
      json['comments'] == null
        ? undefined
        : (json['comments'] as Array<any>).map(CommentFromJSON),
  };
}

export function CommentsResponseToJSON(json: any): CommentsResponse {
  return CommentsResponseToJSONTyped(json, false);
}

export function CommentsResponseToJSONTyped(
  value?: CommentsResponse | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    comments:
      value['comments'] == null
        ? undefined
        : (value['comments'] as Array<any>).map(CommentToJSON),
  };
}
