import { UserSetUpUserStatusEnum } from '@clevergy/api-client';
import { DateRangeType } from '@clevergy/shared/types';
import { Button } from '@clevergy/ui/components/Button';
import { ModuleCard } from '@clevergy/ui/components/ModuleCard';
import BreakdownModuleDraft from 'assets/illustrations/breakdown-module-draft.svg?react';
import ConsumptionModuleDraft from 'assets/illustrations/consumption-module-draft.svg?react';
import { AnalysisDatePicker } from 'components/AnalysisDatePicker';
import { AppAlerts } from 'components/AppAlerts';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { PullToRefresh } from 'components/PullToRefresh';
import { SideBarContainer } from 'components/SideBarContainer';
import { TenantLoadingIndicator } from 'components/TenantLoadingIndicator';
import { Wrapper } from 'components/Wrapper';
import { useAuthContext } from 'context/AuthContext';
import { useTenantSettings } from 'context/TenantSettingsContext';
import { useUserSetupContext } from 'context/UserSetupContext';
import { differenceInDays, format } from 'date-fns';
import { useAnalysisPageFilters } from 'hooks/useAnalysisPageFilters';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { useSmartMeterIntegration } from 'hooks/useSmartMeterIntegration';
import { dateFnsLocales } from 'i18n';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

export const AnalysisPage: FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const locale = dateFnsLocales[language];

  const { token } = useAuthContext();
  const { tenantId } = useTenantSettings();
  const { userStatus, userInfo } = useUserSetupContext();
  const { selectedHouse, selectedHouseDateLimits } = useSelectedHouse();
  const { shouldShowSmartMeterDraftModules } = useSmartMeterIntegration();
  const navigate = useNavigate();

  const {
    dateRangeType,
    dateRangeCurrentDate,
    customStartDate,
    customEndDate,
  } = useAnalysisPageFilters();

  const formattedStartDate = customStartDate
    ? format(customStartDate, 'yyyy-MM-dd')
    : undefined;
  const formattedEndDate = customEndDate
    ? format(customEndDate, 'yyyy-MM-dd')
    : undefined;
  const formattedRangeCurrentDate = dateRangeCurrentDate
    ? format(dateRangeCurrentDate, 'yyyy-MM-dd')
    : undefined;

  const shouldReplaceEnergyWithPower =
    selectedHouse?.dataProviders?.solarInverter &&
    (dateRangeType === DateRangeType.Day ||
      (dateRangeType === DateRangeType.Custom &&
        customStartDate &&
        customEndDate &&
        Math.abs(differenceInDays(customStartDate, customEndDate)) === 0));

  const hasDownloadConsumptionsCsvEnabled = useMemo(() => {
    return tenantId === 'grich' || tenantId === 'instalacionesporcuna';
  }, [tenantId]);

  return (
    <Page>
      <PageHeader withHousePicker>
        {selectedHouseDateLimits.minStartDate &&
          selectedHouseDateLimits.maxEndDate && (
            <AnalysisDatePicker
              texts={{
                day: t('analysis.day'),
                month: t('analysis.month'),
                year: t('analysis.year'),
                custom: t('analysis.custom'),
                cancelButton: t('analysis.cancelButton'),
                okButton: t('analysis.okButton'),
              }}
              locale={locale}
            />
          )}
      </PageHeader>
      <PullToRefresh />

      {/* sidebar */}
      <div className="relative">
        <SideBarContainer>
          <></>
        </SideBarContainer>
      </div>

      <Wrapper>
        {!token && <TenantLoadingIndicator />}
        {token && (
          <>
            <AppAlerts />

            {selectedHouse && (
              <clevergy-solar-summary
                data-token={token}
                data-house-id={selectedHouse.houseId}
                data-show-dates-selector="false"
                data-date-range-type={dateRangeType}
                data-date-range-current-date={formattedRangeCurrentDate}
                data-custom-start-date={formattedStartDate}
                data-custom-end-date={formattedEndDate}
              />
            )}

            {selectedHouse && (
              <>
                {shouldReplaceEnergyWithPower ? (
                  <clevergy-power-chart
                    data-title={t('analysis.solarDailyPowerChartTitle')}
                    data-token={token}
                    data-house-id={selectedHouse.houseId}
                    data-current-date={
                      formattedStartDate || formattedRangeCurrentDate
                    }
                  />
                ) : (
                  <clevergy-energy-chart
                    data-house-id={selectedHouse.houseId}
                    data-token={token}
                    data-show-dates-selector="false"
                    data-date-range-type={dateRangeType}
                    data-date-range-current-date={formattedRangeCurrentDate}
                    data-custom-start-date={formattedStartDate}
                    data-custom-end-date={formattedEndDate}
                    data-show-average-consumption="false"
                    data-show-download-csv={hasDownloadConsumptionsCsvEnabled}
                  ></clevergy-energy-chart>
                )}
              </>
            )}

            {selectedHouse && !userInfo.isCompany && (
              <clevergy-breakdown
                data-token={token}
                data-house-id={selectedHouse.houseId}
                data-show-dates-selector="false"
                data-date-range-type={dateRangeType}
                data-date-range-current-date={formattedRangeCurrentDate}
                data-custom-start-date={formattedStartDate}
                data-custom-end-date={formattedEndDate}
              ></clevergy-breakdown>
            )}

            {shouldShowSmartMeterDraftModules && (
              <>
                <ModuleCard>
                  <ModuleCard.Header
                    title="Tu consumo en detalle"
                    subtitle="Consulta todos tus datos de consumo a lo largo del tiempo"
                  />
                  <ModuleCard.Content>
                    <div className="flex flex-col gap-4">
                      <p></p>
                      <ConsumptionModuleDraft />
                      {userStatus === UserSetUpUserStatusEnum.Initial && (
                        <Button
                          variant="filled"
                          fullWidth
                          onClick={() => navigate('/smart-meter')}
                        >
                          Conectar mis datos de consumo
                        </Button>
                      )}
                    </div>
                  </ModuleCard.Content>
                </ModuleCard>

                {!userInfo.isCompany && (
                  <ModuleCard>
                    <ModuleCard.Header
                      title="Tu consumo explicado"
                      subtitle="Averigua qué electrodomésticos consumen más"
                    />
                    <ModuleCard.Content>
                      <div className="flex flex-col gap-4">
                        <BreakdownModuleDraft />
                        {userStatus === UserSetUpUserStatusEnum.Initial && (
                          <Button
                            variant="filled"
                            fullWidth
                            onClick={() => navigate('/smart-meter')}
                          >
                            Conectar mis datos de consumo
                          </Button>
                        )}
                      </div>
                    </ModuleCard.Content>
                  </ModuleCard>
                )}
              </>
            )}
          </>
        )}
      </Wrapper>
    </Page>
  );
};
