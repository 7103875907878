/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface OauthRedirectUrlResponse
 */
export interface OauthRedirectUrlResponse {
  /**
   *
   * @type {string}
   * @memberof OauthRedirectUrlResponse
   */
  url: string;
}

/**
 * Check if a given object implements the OauthRedirectUrlResponse interface.
 */
export function instanceOfOauthRedirectUrlResponse(
  value: object,
): value is OauthRedirectUrlResponse {
  if (!('url' in value) || value['url'] === undefined) return false;
  return true;
}

export function OauthRedirectUrlResponseFromJSON(
  json: any,
): OauthRedirectUrlResponse {
  return OauthRedirectUrlResponseFromJSONTyped(json, false);
}

export function OauthRedirectUrlResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): OauthRedirectUrlResponse {
  if (json == null) {
    return json;
  }
  return {
    url: json['url'],
  };
}

export function OauthRedirectUrlResponseToJSON(
  json: any,
): OauthRedirectUrlResponse {
  return OauthRedirectUrlResponseToJSONTyped(json, false);
}

export function OauthRedirectUrlResponseToJSONTyped(
  value?: OauthRedirectUrlResponse | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    url: value['url'],
  };
}
