import { TrackedButton } from '@clevergy/amplitude/components/TrackedButton';
import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { ButtonToggleHorizontal } from '@clevergy/ui/components/ButtonToggleHorizontal';
import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { Wrapper } from 'components/Wrapper';
import { useApiContext } from 'context/ApiContext';
import { useAuthContext } from 'context/AuthContext';
import shuffle from 'lodash.shuffle';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

const expectationsList = [
  {
    key: 'home_comparator',
    icon: <FontIcon name="location_city" />,
  },
  {
    key: 'disaggregation',
    icon: <FontIcon name="kitchen" />,
  },
  {
    key: 'rate_recommender',
    icon: <FontIcon name="energy_savings_leaf" />,
  },
  {
    key: 'energy_tips',
    icon: <FontIcon name="local_laundry_service" />,
  },
  {
    key: 'self_consumption_optimization',
    icon: <FontIcon name="solar_power" />,
  },
  {
    key: 'smart_home',
    icon: <FontIcon name="bolt" />,
  },
  {
    key: 'pvpc_price',
    icon: <FontIcon name="currency_exchange" />,
  },
  {
    key: 'marketplace',
    icon: <FontIcon name="dashboard" />,
  },
] as const;

type Expectation = (typeof expectationsList)[number]['key'];

export const ExpectationsPage: FC = () => {
  const { t } = useTranslation();
  const { api } = useApiContext();
  const { authedUser } = useAuthContext();
  const queryClient = useQueryClient();

  const [expectationsToShow] = useState(shuffle(expectationsList));
  const [expectationSelected, setExpectationsSelected] = useState<
    Expectation[]
  >([]);

  const toggleExpectation = (expectation: Expectation) => {
    setExpectationsSelected((prev) =>
      prev.includes(expectation)
        ? prev.filter((element) => element !== expectation)
        : [...prev, expectation],
    );
  };

  const storeUserExpectationsMutation = useMutation({
    mutationFn: (expectations: Expectation[]) =>
      api.users.storeUserExpectations({
        userId: authedUser?.uid || 'me',
        expectations: {
          expectations,
        },
      }),
    onSuccess: async () => {
      // delay for 1 second to allow bigquery to update
      await new Promise((resolve) => setTimeout(resolve, 1000));
      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.GET_USER_EXPECTATIONS],
      });
    },
  });

  const handleSave = () => {
    storeUserExpectationsMutation.mutate(expectationSelected);
  };

  return (
    <Page>
      <PageHeader title={t('expectations.title') as string} />
      <Wrapper>
        <div className="flex flex-col gap-4 h-full pb-safe">
          <p>{t('expectations.subtitle')}</p>
          <div className="flex flex-col items-center gap-2 grow w-full">
            {expectationsToShow.map((item) => {
              const isChecked = expectationSelected?.includes(item.key);
              return (
                <ButtonToggleHorizontal
                  key={item.key}
                  icon={item.icon}
                  title={t(`expectations.items.${item.key}`) as string}
                  isChecked={isChecked}
                  onClick={() => toggleExpectation(item.key)}
                />
              );
            })}
          </div>
          <TrackedButton
            variant="filled"
            fullWidth
            busy={storeUserExpectationsMutation.isPending}
            disabled={storeUserExpectationsMutation.isPending}
            onClick={() => handleSave()}
            event={{
              event_type: 'app_goal_answered',
              event_properties: {
                app_goal:
                  expectationSelected.length > 0 ? expectationSelected : null,
              },
            }}
          >
            {t('expectations.button')}
          </TrackedButton>
        </div>
      </Wrapper>
    </Page>
  );
};
