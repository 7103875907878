/* tslint:disable */

/* eslint-disable */

/**
 * Clevergy Portal Routes
 * Clevergy Portal Routes
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 * Ticket information
 * @export
 * @interface Ticket
 */
export interface Ticket {
  /**
   * Ticket identifier
   * @type {string}
   * @memberof Ticket
   */
  id: string;
  /**
   * Tenant GCP identifier
   * @type {string}
   * @memberof Ticket
   */
  tenantId: string;
  /**
   * User identifier
   * @type {string}
   * @memberof Ticket
   */
  userId?: string;
  /**
   *
   * @type {string}
   * @memberof Ticket
   */
  houseId?: string;
  /**
   *
   * @type {string}
   * @memberof Ticket
   */
  equipmentId?: string;
  /**
   *
   * @type {string}
   * @memberof Ticket
   */
  alertId?: string;
  /**
   * Ticket status
   * @type {string}
   * @memberof Ticket
   */
  status: TicketStatusEnum;
  /**
   * Ticket title
   * @type {string}
   * @memberof Ticket
   */
  title: string;
  /**
   * Ticket description
   * @type {string}
   * @memberof Ticket
   */
  description: string;
  /**
   * Ticket creation date
   * @type {Date}
   * @memberof Ticket
   */
  createdAt: Date;
  /**
   * Ticket last update date
   * @type {Date}
   * @memberof Ticket
   */
  updatedAt: Date;
}

/**
 * @export
 */
export const TicketStatusEnum = {
  New: 'NEW',
  Open: 'OPEN',
  Pending: 'PENDING',
  Solved: 'SOLVED',
} as const;
export type TicketStatusEnum =
  (typeof TicketStatusEnum)[keyof typeof TicketStatusEnum];

/**
 * Check if a given object implements the Ticket interface.
 */
export function instanceOfTicket(value: object): value is Ticket {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('tenantId' in value) || value['tenantId'] === undefined) return false;
  if (!('status' in value) || value['status'] === undefined) return false;
  if (!('title' in value) || value['title'] === undefined) return false;
  if (!('description' in value) || value['description'] === undefined)
    return false;
  if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
  if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
  return true;
}

export function TicketFromJSON(json: any): Ticket {
  return TicketFromJSONTyped(json, false);
}

export function TicketFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Ticket {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    tenantId: json['tenantId'],
    userId: json['userId'] == null ? undefined : json['userId'],
    houseId: json['houseId'] == null ? undefined : json['houseId'],
    equipmentId: json['equipmentId'] == null ? undefined : json['equipmentId'],
    alertId: json['alertId'] == null ? undefined : json['alertId'],
    status: json['status'],
    title: json['title'],
    description: json['description'],
    createdAt: new Date(json['createdAt']),
    updatedAt: new Date(json['updatedAt']),
  };
}

export function TicketToJSON(json: any): Ticket {
  return TicketToJSONTyped(json, false);
}

export function TicketToJSONTyped(
  value?: Ticket | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value['id'],
    tenantId: value['tenantId'],
    userId: value['userId'],
    houseId: value['houseId'],
    equipmentId: value['equipmentId'],
    alertId: value['alertId'],
    status: value['status'],
    title: value['title'],
    description: value['description'],
    createdAt: value['createdAt'].toISOString(),
    updatedAt: value['updatedAt'].toISOString(),
  };
}
