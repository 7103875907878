import { ClevergyEventTypes, useClevergyEvent } from '@clevergy/event-bus';
import { LoadingIndicator } from '@clevergy/ui/components/LoadingIndicator';
import { splitDisplayName } from '@clevergy/utils/format/splitDisplayName';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { Wrapper } from 'components/Wrapper';
import { useAuthContext } from 'context/AuthContext';
import { useTenantSettings } from 'context/TenantSettingsContext';
import { useUserSetupContext } from 'context/UserSetupContext';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const SmartMeterIntegrationPage: FC = () => {
  const { t } = useTranslation();
  const { token, authedUser } = useAuthContext();
  const { legalName, dpoEmail } = useTenantSettings();
  const { refetchSetup } = useUserSetupContext();
  const navigate = useNavigate();

  const [isWaitingForUserStatusRedirect, setIsWaitingForUserStatusRedirect] =
    useState(false);

  useClevergyEvent(ClevergyEventTypes.INTEGRATION_SMARTMETER_SKIPPED, () => {
    if (window.confirm(t('smartmeter.skipConfirmation') as string)) {
      navigate('/');
    }
  });

  useClevergyEvent(
    ClevergyEventTypes.INTEGRATION_SMARTMETER_COMPLETED,
    async () => {
      setIsWaitingForUserStatusRedirect(true);
      await refetchSetup();
    },
  );

  const { firstName, lastName } = splitDisplayName(authedUser?.displayName);

  if (isWaitingForUserStatusRedirect) {
    return <LoadingIndicator fullScreen="overlay" />;
  }

  return (
    <Page>
      <PageHeader title={t('smartmeter.title') as string} />
      <Wrapper>
        <div className="pb-safe h-full">
          {token && dpoEmail && (
            <clevergy-integration-smartmeter
              data-token={token}
              data-consent-name={legalName}
              data-opt-out-email={dpoEmail}
              data-user-firstname={firstName}
              data-user-lastname={lastName}
              data-companies-enabled={'true'}
            />
          )}
        </div>
      </Wrapper>
    </Page>
  );
};
