import { FontIcon } from '../FontIcon';
import { FC } from 'react';

export const ProcessIconWaiting: FC = () => {
  return (
    <div className="text-sky-700">
      <FontIcon
        name="hourglass_empty"
        size={56}
        circleBackgroundClass="bg-sky-50"
      />
    </div>
  );
};
