import { DeleteAccountModal } from './components/DeleteAccountModal';
import { ResetPasswordModal } from './components/ResetPasswordModal';
import { demoAccountSettings } from '@clevergy/shared/constants/demoAccountSettings';
import { useHouseSettingsBackupStore } from '@clevergy/shared/stores/houseSettingsBackupStore';
import { Button } from '@clevergy/ui/components/Button';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { Wrapper } from 'components/Wrapper';
import { useAuthContext } from 'context/AuthContext';
import { useUserSetupContext } from 'context/UserSetupContext';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppConfigurationStore } from 'stores/appConfigurationStore';

export const AccountPage: FC = () => {
  const { t } = useTranslation();

  const { authedUser, signOut } = useAuthContext();
  const { userInfo } = useUserSetupContext();

  const resetAppConfiguration = useAppConfigurationStore(
    (state) => () => state.reset(),
  );

  const resetHouseSettingsBackup = useHouseSettingsBackupStore(
    (state) => state.reset,
  );

  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);

  const isDemoAccount = demoAccountSettings.emailRegex.test(
    authedUser?.email || '',
  );

  return (
    <Page navbarLayoutContentMobileBackground="white">
      <PageHeader
        backButton
        title={t('account.title') as string}
        hideTitleAndBackButtonForNonMobile
      />

      <Wrapper>
        <div className="flex flex-col">
          <ul className="list-none">
            <li className="flex w-full flex-wrap justify-between gap-4 rounded-none p-2">
              <div className="text-left">{t('account.name.key')}</div>
              <div className="truncate text-right font-semibold text-neutral-700">
                {userInfo?.name}
              </div>
            </li>
            <li className="flex w-full flex-wrap justify-between gap-4 rounded-none p-2">
              <div className="text-left">{t('account.email.key')}</div>
              <div className="truncate text-right font-semibold text-neutral-700">
                {userInfo?.email}
              </div>
            </li>
            <li className="flex w-full flex-wrap justify-between gap-4 rounded-none p-2">
              <div className="text-left">{t('account.dni.key')}</div>
              <div className="truncate text-right font-semibold text-neutral-700">
                {userInfo?.dni}
              </div>
            </li>
            <li className="flex w-full flex-wrap justify-between gap-4 rounded-none p-2">
              <div className="text-left">{t('account.password.key')}</div>
              <div className="truncate text-right font-semibold text-neutral-700">
                <button
                  className="text-clevergy-primary"
                  onClick={() => setShowResetPasswordModal(true)}
                >
                  {t('account.password.link')}
                </button>
              </div>
            </li>
            <li className="w-full my-4">
              <Link to={'/logout'}>
                <Button fullWidth colorScheme="primary">
                  {t('account.session.button')}
                </Button>
              </Link>
            </li>
            {!isDemoAccount && (
              <li className="w-full my-4">
                <Button
                  fullWidth
                  variant={'flat'}
                  onClick={() => setShowDeleteAccountModal(true)}
                >
                  {t('account.deleteAccount.button')}
                </Button>
              </li>
            )}
          </ul>
        </div>
      </Wrapper>

      <DeleteAccountModal
        isOpen={showDeleteAccountModal}
        onCloseModal={() => setShowDeleteAccountModal(false)}
        onAccountDeleted={() => {
          setShowDeleteAccountModal(false);
          resetAppConfiguration();
          resetHouseSettingsBackup();
          signOut();
        }}
      />
      <ResetPasswordModal
        isOpen={showResetPasswordModal}
        onCloseModal={() => setShowResetPasswordModal(false)}
      />
    </Page>
  );
};
