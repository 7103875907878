/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  ConnectWithShellyCallbackRequest,
  CreateOauthIntegrationRequest,
  HttpError,
  OauthRedirectUrlResponse,
} from '../models/index';
import {
  ConnectWithShellyCallbackRequestFromJSON,
  ConnectWithShellyCallbackRequestToJSON,
  CreateOauthIntegrationRequestFromJSON,
  CreateOauthIntegrationRequestToJSON,
  HttpErrorFromJSON,
  HttpErrorToJSON,
  OauthRedirectUrlResponseFromJSON,
  OauthRedirectUrlResponseToJSON,
} from '../models/index';
import * as runtime from '../runtime';

export interface ConnectHouseWithOauthIntegrationRequest {
  houseId: string;
  createOauthIntegrationRequest?: CreateOauthIntegrationRequest;
}

export interface ConnectHouseWithShellyRequest {
  houseId: string;
  connectWithShellyCallbackRequest?: ConnectWithShellyCallbackRequest;
}

export interface GetDatadisOAuthUrlRequest {
  userId: string;
}

export interface GetOauthLoginUrlRequest {
  houseId: string;
  vendor: GetOauthLoginUrlVendorEnum;
}

export interface RegisterUserInDatadisRequest {
  userId: string;
}

/**
 *
 */
export class IntegrationsApi extends runtime.BaseAPI {
  /**
   * Connects house with oauth integration (Huawei)
   */
  async connectHouseWithOauthIntegrationRaw(
    requestParameters: ConnectHouseWithOauthIntegrationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['houseId'] == null) {
      throw new runtime.RequiredError(
        'houseId',
        'Required parameter "houseId" was null or undefined when calling connectHouseWithOauthIntegration().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/houses/{houseId}/integrations`.replace(
          `{${'houseId'}}`,
          encodeURIComponent(String(requestParameters['houseId'])),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateOauthIntegrationRequestToJSON(
          requestParameters['createOauthIntegrationRequest'],
        ),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Connects house with oauth integration (Huawei)
   */
  async connectHouseWithOauthIntegration(
    requestParameters: ConnectHouseWithOauthIntegrationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.connectHouseWithOauthIntegrationRaw(
      requestParameters,
      initOverrides,
    );
  }

  /**
   * Connects user with Shelly
   */
  async connectHouseWithShellyRaw(
    requestParameters: ConnectHouseWithShellyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['houseId'] == null) {
      throw new runtime.RequiredError(
        'houseId',
        'Required parameter "houseId" was null or undefined when calling connectHouseWithShelly().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/houses/{houseId}/integrations/shelly`.replace(
          `{${'houseId'}}`,
          encodeURIComponent(String(requestParameters['houseId'])),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ConnectWithShellyCallbackRequestToJSON(
          requestParameters['connectWithShellyCallbackRequest'],
        ),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Connects user with Shelly
   */
  async connectHouseWithShelly(
    requestParameters: ConnectHouseWithShellyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.connectHouseWithShellyRaw(requestParameters, initOverrides);
  }

  /**
   * retrieves datadis oauth url
   */
  async getDatadisOAuthUrlRaw(
    requestParameters: GetDatadisOAuthUrlRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<OauthRedirectUrlResponse>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling getDatadisOAuthUrl().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/integrations/datadis/url`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters['userId'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      OauthRedirectUrlResponseFromJSON(jsonValue),
    );
  }

  /**
   * retrieves datadis oauth url
   */
  async getDatadisOAuthUrl(
    requestParameters: GetDatadisOAuthUrlRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<OauthRedirectUrlResponse> {
    const response = await this.getDatadisOAuthUrlRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Returns URL where oauth user must login
   */
  async getOauthLoginUrlRaw(
    requestParameters: GetOauthLoginUrlRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<OauthRedirectUrlResponse>> {
    if (requestParameters['houseId'] == null) {
      throw new runtime.RequiredError(
        'houseId',
        'Required parameter "houseId" was null or undefined when calling getOauthLoginUrl().',
      );
    }

    if (requestParameters['vendor'] == null) {
      throw new runtime.RequiredError(
        'vendor',
        'Required parameter "vendor" was null or undefined when calling getOauthLoginUrl().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/houses/{houseId}/integrations/{vendor}/url`
          .replace(
            `{${'houseId'}}`,
            encodeURIComponent(String(requestParameters['houseId'])),
          )
          .replace(
            `{${'vendor'}}`,
            encodeURIComponent(String(requestParameters['vendor'])),
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      OauthRedirectUrlResponseFromJSON(jsonValue),
    );
  }

  /**
   * Returns URL where oauth user must login
   */
  async getOauthLoginUrl(
    requestParameters: GetOauthLoginUrlRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<OauthRedirectUrlResponse> {
    const response = await this.getOauthLoginUrlRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Registers an user in Datadis
   */
  async registerUserInDatadisRaw(
    requestParameters: RegisterUserInDatadisRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling registerUserInDatadis().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/integrations/datadis`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters['userId'])),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Registers an user in Datadis
   */
  async registerUserInDatadis(
    requestParameters: RegisterUserInDatadisRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.registerUserInDatadisRaw(requestParameters, initOverrides);
  }
}

/**
 * @export
 */
export const GetOauthLoginUrlVendorEnum = {
  HuaweiOauth: 'HUAWEI_OAUTH',
} as const;
export type GetOauthLoginUrlVendorEnum =
  (typeof GetOauthLoginUrlVendorEnum)[keyof typeof GetOauthLoginUrlVendorEnum];
