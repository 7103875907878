import {
  TicketsResponse,
  TicketsResponseStatusEnum,
} from '@clevergy/api-client';
import { Badge } from '@clevergy/ui/components/Badge';
import { FontIcon } from '@clevergy/ui/components/FontIcon';
import clsx from 'clsx';
import { format } from 'date-fns';
import { dateFnsLocales } from 'i18n';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type TicketResumeProp = {
  ticket: TicketsResponse;
};

export const TicketResume: FC<TicketResumeProp> = (props) => {
  const { ticket } = props;

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const getBadgeColor = (status: string) => {
    switch (status) {
      case TicketsResponseStatusEnum.New:
        return 'info';
      case TicketsResponseStatusEnum.Open:
        return 'info';
      case TicketsResponseStatusEnum.Pending:
        return 'danger';
      case TicketsResponseStatusEnum.Solved:
        return 'neutral';
      default:
        return 'neutral';
    }
  };

  return (
    <Link
      to={`/support/tickets/${ticket.id}`}
      className={clsx(
        'flex justify-between items-center gap-2 w-full shadow-md rounded-xl p-3 mb-4',
        {
          'bg-neutral-100': ticket.status === TicketsResponseStatusEnum.Solved,
        },
      )}
    >
      <div className="flex flex-col gap-2">
        <div className="flex gap-2 items-center text-sm">
          <span>
            {format(ticket.createdAt, 'd MMMM H:mm', {
              locale: dateFnsLocales[language],
            })}
          </span>
          <Badge colorScheme={getBadgeColor(ticket.status)}>
            {t(`support.status.${ticket.status}`)}
          </Badge>
        </div>
        <div className="font-semibold">{t(ticket.title)}</div>
      </div>

      <div className="text-slate-400">
        <FontIcon name="chevron_right" size={32} />
      </div>
    </Link>
  );
};
