import { Button } from '@clevergy/ui/components/Button';
import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { FC } from 'react';

export type LeadCaptureSuccessProps = {
  onClick: () => void;
};

export const LeadCaptureSuccess: FC<LeadCaptureSuccessProps> = ({
  onClick,
}) => {
  return (
    <div className="flex flex-col justify-center items-center h-full text-center gap-4">
      <div className="flex flex-col items-center gap-4">
        <div className="text-clevergy-primary mb-4">
          <FontIcon
            name="check"
            size={56}
            circleBackgroundClass="bg-clevergy-primary/10"
          />
        </div>

        <h2 className="text-xl mb-1 font-semibold">¡Estupendo!</h2>

        <p>
          Muy pronto nos pondremos en contacto contigo para hacerte una
          recomendación personalizada.
        </p>
      </div>

      <Button
        colorScheme="primary"
        variant={'filled'}
        onClick={onClick}
        fullWidth
      >
        Finalizar
      </Button>
    </div>
  );
};
