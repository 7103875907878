import { Button } from '@clevergy/ui/components/Button';
import { Heading } from '@clevergy/ui/components/Heading';
import { ProcessIconFailed } from '@clevergy/ui/components/ProcessIconFailed';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export type FailedScreenProps = {
  title: ReactNode;
  description: ReactNode;
  onRetry: () => void;
};

export const FailedScreen: FC<FailedScreenProps> = (props) => {
  const { onRetry, title, description } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4 h-full">
      <div className="grow flex flex-col text-center items-center justify-center px-8 gap-4">
        <ProcessIconFailed />

        <div>
          <Heading as="h2" colorScheme="neutral">
            {title}
          </Heading>
          <>{description}</>
        </div>
      </div>

      <Button variant="flat" fullWidth onClick={() => navigate('/support')}>
        {t('oauth.common.supportButton')}
      </Button>

      <Button onClick={onRetry} variant="filled" as="a">
        {t('oauth.common.retryButton')}
      </Button>
    </div>
  );
};
