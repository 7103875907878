/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type { HouseContractDetail, HttpError } from '../models/index';
import {
  HouseContractDetailFromJSON,
  HouseContractDetailToJSON,
  HttpErrorFromJSON,
  HttpErrorToJSON,
} from '../models/index';
import * as runtime from '../runtime';

export interface GetHouseLastContractRequest {
  houseId: string;
}

/**
 *
 */
export class ContractsApi extends runtime.BaseAPI {
  /**
   * Get a detailed description of the last contract
   */
  async getHouseLastContractRaw(
    requestParameters: GetHouseLastContractRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<HouseContractDetail>> {
    if (requestParameters['houseId'] == null) {
      throw new runtime.RequiredError(
        'houseId',
        'Required parameter "houseId" was null or undefined when calling getHouseLastContract().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/houses/{houseId}/last-contract`.replace(
          `{${'houseId'}}`,
          encodeURIComponent(String(requestParameters['houseId'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      HouseContractDetailFromJSON(jsonValue),
    );
  }

  /**
   * Get a detailed description of the last contract
   */
  async getHouseLastContract(
    requestParameters: GetHouseLastContractRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<HouseContractDetail | null | undefined> {
    const response = await this.getHouseLastContractRaw(
      requestParameters,
      initOverrides,
    );
    switch (response.raw.status) {
      case 200:
        return await response.value();
      case 204:
        return null;
      default:
        return await response.value();
    }
  }
}
