import { LienzoBanner } from './components/LienzoBanner';
import { UserSetUpUserStatusEnum } from '@clevergy/api-client';
import { TenantSettingsEnergyPricesTypeEnum } from '@clevergy/api-client-portal-routes';
import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { Button } from '@clevergy/ui/components/Button';
import { ModuleCard } from '@clevergy/ui/components/ModuleCard';
import { skipToken, useQuery } from '@tanstack/react-query';
import ComparisonModuleDraft from 'assets/illustrations/comparison-module-draft.svg?react';
import { AppAlerts } from 'components/AppAlerts';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { PullToRefresh } from 'components/PullToRefresh';
import { TenantLoadingIndicator } from 'components/TenantLoadingIndicator';
import { Wrapper } from 'components/Wrapper';
import { useApiContext } from 'context/ApiContext';
import { useAuthContext } from 'context/AuthContext';
import { useTenantSettings } from 'context/TenantSettingsContext';
import { useUserSetupContext } from 'context/UserSetupContext';
import { format } from 'date-fns';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { useSmartMeterIntegration } from 'hooks/useSmartMeterIntegration';
import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router';

const tooltipHouseComparison = `
<ul>
  <li>
    <strong>Hogares eficientes:</strong>
    consumo medio de hogares con
    características similares según tu configuración de la cuenta,
    teniendo en cuenta solo el 30% de los hogares con menor consumo.
  </li>
  <li>
    <strong>Hogares similares:</strong>
    consumo medio de hogares con características similares según tu configuración de la cuenta.
  </li>
  <li>
    <strong>Tus vecinos:</strong>
    consumo medio de hogares con el mismo código postal que tú.
  </li>
</ul>
`;

export const HomePage: FC = () => {
  const { token } = useAuthContext();
  const { userStatus, userInfo } = useUserSetupContext();
  const { selectedHouse } = useSelectedHouse();
  const { shouldShowSmartMeterDraftModules } = useSmartMeterIntegration();
  const { tenantId } = useTenantSettings();
  const { energyPricesType: energyPricesTypeFromTenant } = useTenantSettings();
  const navigate = useNavigate();

  const { api } = useApiContext();

  const houseContractQuery = useQuery({
    queryKey: [
      QueryKeys.HOUSE_CONTRACT,
      {
        houseId: selectedHouse?.houseId,
      },
    ],
    queryFn:
      selectedHouse?.houseId &&
      energyPricesTypeFromTenant === TenantSettingsEnergyPricesTypeEnum.Contract
        ? () =>
            api.contracts.getHouseLastContract({
              houseId: selectedHouse?.houseId,
            })
        : skipToken,
  });

  const energyPricesType:
    | Omit<TenantSettingsEnergyPricesTypeEnum, 'CONTRACT'>
    | undefined = useMemo(() => {
    if (
      energyPricesTypeFromTenant === TenantSettingsEnergyPricesTypeEnum.Contract
    ) {
      if (houseContractQuery.data?.margin) {
        return 'OMIE';
      }

      if (houseContractQuery.data?.pvpc) {
        return 'PVPC';
      }

      return 'NONE';
    }

    return energyPricesTypeFromTenant;
  }, [
    energyPricesTypeFromTenant,
    houseContractQuery.data?.margin,
    houseContractQuery.data?.pvpc,
  ]);

  return (
    <Page>
      <PageHeader withHousePicker />
      <PullToRefresh />
      <Wrapper>
        {!token && <TenantLoadingIndicator />}

        {token && (
          <>
            {tenantId === 'niba' && <LienzoBanner />}

            <AppAlerts />

            {selectedHouse && (
              <clevergy-solar-real-time
                data-token={token}
                data-house-id={selectedHouse.houseId}
              />
            )}

            {selectedHouse && (
              <clevergy-power-chart
                data-token={token}
                data-house-id={selectedHouse.houseId}
                data-current-date={format(new Date(), 'yyyy-MM-dd')}
              />
            )}

            {selectedHouse && (
              <clevergy-solar-info
                data-token={token}
                data-house-id={selectedHouse.houseId}
              />
            )}

            {selectedHouse && (
              <clevergy-smart-devices
                data-token={token}
                data-house-id={selectedHouse.houseId}
              />
            )}

            {energyPricesType &&
              energyPricesType !== TenantSettingsEnergyPricesTypeEnum.None && (
                <clevergy-energy-prices
                  data-show-energy-price-surplus={
                    selectedHouse?.dataProviders?.solarInverter
                      ? 'true'
                      : 'false'
                  }
                  data-show-energy-price-list={
                    tenantId === 'selectra' ? 'true' : 'false'
                  }
                  data-energy-prices-type={energyPricesType}
                />
              )}

            {selectedHouse && (
              <clevergy-consumption-cumulative
                data-token={token}
                data-house-id={selectedHouse.houseId}
                data-tooltip={''}
              />
            )}

            {selectedHouse && (
              <clevergy-invoice-viewer
                data-token={token}
                data-house-id={selectedHouse.houseId}
              />
            )}

            {selectedHouse && (
              <clevergy-virtual-wallet
                data-token={token}
                data-house-id={selectedHouse.houseId}
              />
            )}

            {selectedHouse && (
              <clevergy-virtual-battery
                data-token={token}
                data-house-id={selectedHouse.houseId}
              />
            )}

            {selectedHouse &&
              !userInfo.isCompany &&
              tenantId !== 'comunidad-solar' && (
                <clevergy-house-comparison
                  data-token={token}
                  data-house-id={selectedHouse.houseId}
                  data-view="race"
                  data-variant="advanced"
                  data-tooltip={tooltipHouseComparison}
                />
              )}

            {shouldShowSmartMeterDraftModules &&
              tenantId !== 'comunidad-solar' && (
                <ModuleCard>
                  <ModuleCard.Header
                    title="Comparador de hogares"
                    subtitle="Compara tu consumo con el de otros hogares similares"
                  />
                  <ModuleCard.Content>
                    <div className="flex flex-col gap-4">
                      <ComparisonModuleDraft />
                      {userStatus === UserSetUpUserStatusEnum.Initial && (
                        <Button
                          variant="filled"
                          fullWidth
                          onClick={() => navigate('/smart-meter')}
                        >
                          Conectar mis datos de consumo
                        </Button>
                      )}
                    </div>
                  </ModuleCard.Content>
                </ModuleCard>
              )}
          </>
        )}
      </Wrapper>
    </Page>
  );
};
