import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { Icon } from '@clevergy/ui/components/Icon';
import { List } from '@clevergy/ui/components/List';
import { AppVersionInfo } from 'components/AppVersionInfo';
import { Page } from 'components/Page';
import { Wrapper } from 'components/Wrapper';
import { useUserSetupContext } from 'context/UserSetupContext';
import { IntegrationId } from 'hooks/useIntegrations';
import { useIntegrations } from 'hooks/useIntegrations';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const ProfilePage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userInfo } = useUserSetupContext();
  const { selectedHouse } = useSelectedHouse();

  const { integrationsList } = useIntegrations();

  const shellyIntegration = integrationsList.find(
    (integration) => integration.id === IntegrationId.Shelly,
  );

  return (
    <Page navbarLayoutContentMobileBackground="white">
      <Wrapper>
        <div className="flex flex-col p-safe">
          <div className="grow">
            <List>
              <>
                <List.Item
                  leftIcon={<FontIcon name="person" />}
                  rightIcon={<FontIcon name="chevron_right" />}
                  onClick={() => navigate('/account')}
                >
                  {t('navbar.account')}
                </List.Item>

                {selectedHouse && !userInfo.isCompany && (
                  <List.Item
                    leftIcon={<FontIcon name="home" />}
                    rightIcon={<FontIcon name="chevron_right" />}
                    onClick={() =>
                      navigate(`/houses/${selectedHouse.houseId}/settings`)
                    }
                  >
                    {t('navbar.houseSettings')}
                  </List.Item>
                )}

                {selectedHouse && (
                  <List.Item
                    leftIcon={<FontIcon name="contract_edit" />}
                    rightIcon={<FontIcon name="chevron_right" />}
                    onClick={() =>
                      navigate(`/houses/${selectedHouse.houseId}/contract`)
                    }
                  >
                    {t('navbar.contract')}
                  </List.Item>
                )}

                {selectedHouse && (
                  <List.Item
                    leftIcon={<FontIcon name="article" />}
                    rightIcon={<FontIcon name="chevron_right" />}
                    onClick={() =>
                      navigate(`/houses/${selectedHouse.houseId}/invoices`)
                    }
                  >
                    {t('navbar.invoices')}
                  </List.Item>
                )}

                {selectedHouse && shellyIntegration?.isConnected && (
                  <List.Item
                    leftIcon={<Icon name="SmartPlug" />}
                    rightIcon={<FontIcon name="chevron_right" />}
                    onClick={() =>
                      navigate(`/houses/${selectedHouse.houseId}/smart-devices`)
                    }
                  >
                    {t('navbar.smartDevices')}
                  </List.Item>
                )}

                {selectedHouse && integrationsList.length > 0 && (
                  <List.Item
                    leftIcon={<FontIcon name="settings_remote" />}
                    rightIcon={<FontIcon name="chevron_right" />}
                    onClick={() =>
                      navigate(`/houses/${selectedHouse.houseId}/integrations`)
                    }
                  >
                    {t('navbar.integrations')}
                  </List.Item>
                )}

                {selectedHouse && (
                  <List.Item
                    leftIcon={<FontIcon name="notifications" />}
                    rightIcon={<FontIcon name="chevron_right" />}
                    onClick={() =>
                      navigate(`/houses/${selectedHouse.houseId}/alerts`)
                    }
                  >
                    {t('navbar.alerts')}
                  </List.Item>
                )}

                <List.Item
                  leftIcon={<FontIcon name="contact_support" />}
                  rightIcon={<FontIcon name="chevron_right" />}
                  onClick={() => navigate('/support')}
                >
                  {t('navbar.support')}
                </List.Item>

                <List.Item
                  leftIcon={<FontIcon name="phone" />}
                  rightIcon={<FontIcon name="chevron_right" />}
                  onClick={() => navigate('/contact')}
                >
                  {t('navbar.contact')}
                </List.Item>
              </>
            </List>
          </div>

          <AppVersionInfo />
        </div>
      </Wrapper>
    </Page>
  );
};
