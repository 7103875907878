/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface CreateOauthIntegrationRequest
 */
export interface CreateOauthIntegrationRequest {
  /**
   *
   * @type {string}
   * @memberof CreateOauthIntegrationRequest
   */
  vendor?: CreateOauthIntegrationRequestVendorEnum;
  /**
   *
   * @type {string}
   * @memberof CreateOauthIntegrationRequest
   */
  code?: string;
}

/**
 * @export
 */
export const CreateOauthIntegrationRequestVendorEnum = {
  HuaweiOauth: 'HUAWEI_OAUTH',
} as const;
export type CreateOauthIntegrationRequestVendorEnum =
  (typeof CreateOauthIntegrationRequestVendorEnum)[keyof typeof CreateOauthIntegrationRequestVendorEnum];

/**
 * Check if a given object implements the CreateOauthIntegrationRequest interface.
 */
export function instanceOfCreateOauthIntegrationRequest(
  value: object,
): value is CreateOauthIntegrationRequest {
  return true;
}

export function CreateOauthIntegrationRequestFromJSON(
  json: any,
): CreateOauthIntegrationRequest {
  return CreateOauthIntegrationRequestFromJSONTyped(json, false);
}

export function CreateOauthIntegrationRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CreateOauthIntegrationRequest {
  if (json == null) {
    return json;
  }
  return {
    vendor: json['vendor'] == null ? undefined : json['vendor'],
    code: json['code'] == null ? undefined : json['code'],
  };
}

export function CreateOauthIntegrationRequestToJSON(
  json: any,
): CreateOauthIntegrationRequest {
  return CreateOauthIntegrationRequestToJSONTyped(json, false);
}

export function CreateOauthIntegrationRequestToJSONTyped(
  value?: CreateOauthIntegrationRequest | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    vendor: value['vendor'],
    code: value['code'],
  };
}
