import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC, useEffect } from 'react';
import { matchRoutes, useLocation, useNavigate, Outlet } from 'react-router';

/**
 * If user reaches a house page without a houseId in the URL and has a selected house, redirect to the selected house page.
 * @returns null
 */
export const HousePagesRedirect: FC = () => {
  const { selectedHouse } = useSelectedHouse();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const matchesWithoutHouseIdParam = matchRoutes(
      [
        { path: '/' },
        { path: '/analysis' },
        { path: '/optimize' },
        { path: '/alerts' },
        { path: '/invoices' },
        { path: '/settings' },
        { path: '/smart-devices' },
        { path: '/integrations' },
      ],
      location,
    );
    if (matchesWithoutHouseIdParam && selectedHouse) {
      const newUrl = `/houses/${selectedHouse.houseId}${matchesWithoutHouseIdParam[0].pathname}`;
      navigate(newUrl, { replace: true });
    }
  }, [selectedHouse, navigate, location]);

  return <Outlet />;
};
