import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { useQuery } from '@tanstack/react-query';
import { useApiContext } from 'context/ApiContext';
import { useTenantSettings } from 'context/TenantSettingsContext';
import { useMemo } from 'react';
import { useAppConfigurationStore } from 'stores/appConfigurationStore';

const EXCLUDED_DOMAINS = ['developer.clever.gy'];

export type UserCompletionsItem = {
  path: string;
  pending: boolean;
};

export type UserCompletionsWhileOnboardingState = {
  /**
   * Whether or not the state is resolving because of pending queries.
   */
  isResolving: boolean;
  /**
   * Whether or not there are pending user completeions.
   */
  hasPendingUserCompletions: () => boolean;
  /**
   * Returns the paths of the pending user completions.
   */
  getPendingUserCompletionsPaths: () => string[];
  /**
   * Returns the next pending user completion.
   * If there are no pending completions, returns undefined.
   */
  getNextPendingCompletion: () => UserCompletionsItem | undefined;
};

export const useUserCompletionsWhileOnboarding =
  (): UserCompletionsWhileOnboardingState => {
    const { api } = useApiContext();
    const { tenantId } = useTenantSettings();
    const { hasSkippedCreateHouseContract } = useAppConfigurationStore();

    // Get expectations answered by the user
    const expectationsQuery = useQuery({
      queryKey: [QueryKeys.GET_USER_EXPECTATIONS],
      queryFn: async () => await api.expectations.getUserExpectations(),
    });

    const steps = useMemo(() => {
      return {
        expectations: {
          path: '/expectations',
          pending:
            expectationsQuery.data?.hasUserAnsweredExpectations === false &&
            !EXCLUDED_DOMAINS.includes(window.location.hostname),
        },
        createHouseContract: {
          path: '/create-house-contract',
          pending: tenantId === 'niba' && !hasSkippedCreateHouseContract,
        },
      };
    }, [
      expectationsQuery.data?.hasUserAnsweredExpectations,
      hasSkippedCreateHouseContract,
      tenantId,
    ]);

    const resultState: UserCompletionsWhileOnboardingState = {
      isResolving: expectationsQuery.isPending,
      hasPendingUserCompletions: () =>
        Object.values(steps).some((completion) => completion.pending),
      getPendingUserCompletionsPaths: () =>
        Object.values(steps).map((completion) => completion.path),
      getNextPendingCompletion: () =>
        Object.values(steps).find((completion) => completion.pending),
    };

    return resultState;
  };
