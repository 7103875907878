import { Button } from '@clevergy/ui/components/Button';
import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { Page } from 'components/Page';
import { Wrapper } from 'components/Wrapper';
import { useNotificationsContext } from 'context/NotificationsContext';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

export const SupportTicketSuccessPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { requestNotificationsPermission } = useNotificationsContext();

  return (
    <Page navbarLayoutContentMobileBackground="white">
      <Wrapper>
        <div className="flex h-full flex-col gap-6 pt-safe">
          <div className="flex grow flex-col justify-center text-center">
            <div className="flex items-center justify-center w-52 h-52 mx-auto text-clevergy-primary">
              <FontIcon
                name="check"
                size={56}
                circleBackgroundClass="bg-clevergy-primary/10"
              />
            </div>
            <p className="text-xl font-semibold text-center py-4">
              {t('support.successTicket.title')}
            </p>
            <p className="text-base text-neutral-700 text-center px-4">
              {t('support.successTicket.message1')}{' '}
              {t('support.successTicket.message2')}{' '}
              <button
                onClick={() => requestNotificationsPermission()}
                className="text-clevergy-primary"
              >
                {t('support.successTicket.phoneSettingsLink')}
              </button>
            </p>
          </div>
          <div>
            <Button
              fullWidth
              onClick={() =>
                navigate('/', {
                  replace: true,
                })
              }
            >
              {t('support.successTicket.actions.ok')}
            </Button>
          </div>
        </div>
      </Wrapper>
    </Page>
  );
};
