/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 * create ticket
 * @export
 * @interface CreateTicketRequest
 */
export interface CreateTicketRequest {
  /**
   *
   * @type {string}
   * @memberof CreateTicketRequest
   */
  tenantId: string;
  /**
   *
   * @type {string}
   * @memberof CreateTicketRequest
   */
  userId?: string;
  /**
   *
   * @type {string}
   * @memberof CreateTicketRequest
   */
  houseId?: string;
  /**
   *
   * @type {string}
   * @memberof CreateTicketRequest
   */
  equipmentId?: string;
  /**
   *
   * @type {string}
   * @memberof CreateTicketRequest
   */
  alertId?: string;
  /**
   *
   * @type {string}
   * @memberof CreateTicketRequest
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof CreateTicketRequest
   */
  description: string;
}

/**
 * Check if a given object implements the CreateTicketRequest interface.
 */
export function instanceOfCreateTicketRequest(
  value: object,
): value is CreateTicketRequest {
  if (!('tenantId' in value) || value['tenantId'] === undefined) return false;
  if (!('title' in value) || value['title'] === undefined) return false;
  if (!('description' in value) || value['description'] === undefined)
    return false;
  return true;
}

export function CreateTicketRequestFromJSON(json: any): CreateTicketRequest {
  return CreateTicketRequestFromJSONTyped(json, false);
}

export function CreateTicketRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CreateTicketRequest {
  if (json == null) {
    return json;
  }
  return {
    tenantId: json['tenantId'],
    userId: json['userId'] == null ? undefined : json['userId'],
    houseId: json['houseId'] == null ? undefined : json['houseId'],
    equipmentId: json['equipmentId'] == null ? undefined : json['equipmentId'],
    alertId: json['alertId'] == null ? undefined : json['alertId'],
    title: json['title'],
    description: json['description'],
  };
}

export function CreateTicketRequestToJSON(json: any): CreateTicketRequest {
  return CreateTicketRequestToJSONTyped(json, false);
}

export function CreateTicketRequestToJSONTyped(
  value?: CreateTicketRequest | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    tenantId: value['tenantId'],
    userId: value['userId'],
    houseId: value['houseId'],
    equipmentId: value['equipmentId'],
    alertId: value['alertId'],
    title: value['title'],
    description: value['description'],
  };
}
