import { Button } from '@clevergy/ui/components/Button';
import { Page } from 'components/Page';
import { Wrapper } from 'components/Wrapper';

export const AppErrorPage = () => {
  const handleRetry = () => {
    window.location.reload();
  };

  return (
    <Page>
      <Wrapper>
        <div className="flex flex-col items-center justify-center gap-4 p-safe h-full text-center">
          <p>No es posible cargar la aplicación. Inténtalo mas tarde.</p>
          <Button onClick={handleRetry} variant="filled">
            Reintentar
          </Button>
        </div>
      </Wrapper>
    </Page>
  );
};
