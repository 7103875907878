import { TicketsResponse } from '@clevergy/api-client';
import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { format } from 'date-fns';
import { dateFnsLocales } from 'i18n';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type TicketDescriptionProp = {
  ticket: TicketsResponse;
};

export const TicketDescription: FC<TicketDescriptionProp> = (props) => {
  const { ticket } = props;

  const {
    i18n: { language },
  } = useTranslation();

  if (!ticket) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2 p-4 bg-gray-100 rounded-lg">
      <div className="flex gap-2 items-center text-sm">
        <p>
          <FontIcon name="account_circle" />
        </p>
        <span className="text-sm">
          {format(ticket.createdAt, 'd MMMM H:mm', {
            locale: dateFnsLocales[language],
          })}
        </span>
      </div>

      <p>{ticket.description}</p>
    </div>
  );
};
