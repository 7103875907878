import { FontIcon } from '../FontIcon';
import { FC, ReactNode } from 'react';

export type InlineRecommendationProps = {
  content: ReactNode;
  withIcon?: boolean;
};

export const InlineRecommendation: FC<InlineRecommendationProps> = (props) => {
  const { content, withIcon } = props;

  return (
    <div className="flex items-start gap-2 text-clevergy-primary bg-clevergy-primary/10 rounded-xl py-2 px-4">
      {withIcon && (
        <div className="py-1">
          <FontIcon name="thumb_up" size={16} />
        </div>
      )}
      <p>{content}</p>
    </div>
  );
};
