import { Button } from '@clevergy/ui/components/Button';
import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { Icon } from '@clevergy/ui/components/Icon';
import { Modal } from '@clevergy/ui/components/Modal';
import { useShellyConnect } from 'hooks/useShellyConnect';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

interface Props {
  houseId: string;
  deviceName: string;
  isOpen: boolean;
  onCloseModal: () => void;
}

export const DeleteDeviceModal: FC<Props> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { openShellyConnect } = useShellyConnect();

  return (
    <Modal isOpen={props.isOpen} onClose={() => props.onCloseModal()}>
      <div className="mt-8">
        <div className="flex justify-center items-center gap-4 mb-8">
          <Icon name="Clevergy" size={50} />
          <FontIcon name="link" />
          <Icon name="Shelly" size={40} />
        </div>

        <div className="flex flex-col gap-4 text-center">
          <p className="text-xl font-semibold">
            {t('smartDevices.deleteDevice.title')}
          </p>

          <div className="text-sm">
            <p>
              <Trans
                values={{
                  deviceName:
                    props.deviceName || t('smartDevices.deleteDevice.noName'),
                }}
              >
                smartDevices.deleteDevice.subtitle
              </Trans>
            </p>
          </div>

          <div className="mt-4">
            <Button
              fullWidth
              onClick={() => {
                openShellyConnect();
                navigate(`/houses/${props.houseId}/smart-devices`);
                props.onCloseModal();
              }}
            >
              {t('smartDevices.deleteDevice.actions.delete')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
