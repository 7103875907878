/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { CommentsResponse } from './CommentsResponse';
import {
  CommentsResponseFromJSON,
  CommentsResponseFromJSONTyped,
  CommentsResponseToJSON,
  CommentsResponseToJSONTyped,
} from './CommentsResponse';
import type { TicketsResponse } from './TicketsResponse';
import {
  TicketsResponseFromJSON,
  TicketsResponseFromJSONTyped,
  TicketsResponseToJSON,
  TicketsResponseToJSONTyped,
} from './TicketsResponse';

/**
 * ticket with comments
 * @export
 * @interface TicketDetailedResponse
 */
export interface TicketDetailedResponse {
  /**
   *
   * @type {TicketsResponse}
   * @memberof TicketDetailedResponse
   */
  ticketsResponse?: TicketsResponse;
  /**
   *
   * @type {CommentsResponse}
   * @memberof TicketDetailedResponse
   */
  commentsResponse?: CommentsResponse;
}

/**
 * Check if a given object implements the TicketDetailedResponse interface.
 */
export function instanceOfTicketDetailedResponse(
  value: object,
): value is TicketDetailedResponse {
  return true;
}

export function TicketDetailedResponseFromJSON(
  json: any,
): TicketDetailedResponse {
  return TicketDetailedResponseFromJSONTyped(json, false);
}

export function TicketDetailedResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TicketDetailedResponse {
  if (json == null) {
    return json;
  }
  return {
    ticketsResponse:
      json['ticketsResponse'] == null
        ? undefined
        : TicketsResponseFromJSON(json['ticketsResponse']),
    commentsResponse:
      json['commentsResponse'] == null
        ? undefined
        : CommentsResponseFromJSON(json['commentsResponse']),
  };
}

export function TicketDetailedResponseToJSON(
  json: any,
): TicketDetailedResponse {
  return TicketDetailedResponseToJSONTyped(json, false);
}

export function TicketDetailedResponseToJSONTyped(
  value?: TicketDetailedResponse | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    ticketsResponse: TicketsResponseToJSON(value['ticketsResponse']),
    commentsResponse: CommentsResponseToJSON(value['commentsResponse']),
  };
}
