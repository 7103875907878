const ALEXA_CLIENT_ID = `amzn1.application-oa2-client.28929ed0fd9c4073a44fe9d11b2c7af3`;
const ALEXA_SKILL_STAGE = 'live';

export const getAlexaIntegrationConnectUrl = () => {
  const baseUrl = 'https://alexa.amazon.com/spa/skill-account-linking-consent';

  const params = new URLSearchParams();
  params.set('response_type', 'code');
  params.set('client_id', ALEXA_CLIENT_ID);
  params.set('skill_stage', ALEXA_SKILL_STAGE);
  params.set('scope', 'alexa::skills:account_linking');
  params.set('fragment', 'skill-account-linking-consent');
  params.set('state', Math.random().toString(36).substring(2, 15));
  params.set(
    'redirect_uri',
    'https://app.clever.gy/profile/integrations/alexa/callback',
  );

  return `${baseUrl}?${params.toString()}`;
};
