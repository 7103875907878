import { Browser } from '@capacitor/browser';
import { useNavigate } from 'react-router';

export type IntegrationUrl = {
  url: string;
  openInAppBrowser?: boolean;
};

export const useOpenIntegrationUrl = () => {
  const navigate = useNavigate();

  const openIntegrationUrl = async (options?: {
    integrationUrl?: IntegrationUrl | null;
    onFinished?: () => void;
  }) => {
    const { integrationUrl, onFinished } = options || {};

    if (!integrationUrl) {
      return;
    }

    if (integrationUrl.openInAppBrowser) {
      await Browser.addListener('browserFinished', () => {
        Browser.removeAllListeners();
        onFinished?.();
      });

      await Browser.open({ url: integrationUrl.url });
      return;
    }

    if (integrationUrl.url.startsWith('http')) {
      window.open(integrationUrl.url, '_blank');
      onFinished?.();
      return;
    }

    navigate(integrationUrl.url);
    onFinished?.();
    return;
  };

  return { openIntegrationUrl };
};
