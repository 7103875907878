import { Button } from '@clevergy/ui/components/Button';
import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { Icon } from '@clevergy/ui/components/Icon';
import { LoadingIndicator } from '@clevergy/ui/components/LoadingIndicator';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { Wrapper } from 'components/Wrapper';
import { IntegrationId, useIntegrations } from 'hooks/useIntegrations';
import { useOpenIntegrationUrl } from 'hooks/useOpenIntegrationUrl';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { NotFoundPage } from 'pages/errors/NotFoundPage';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router';

export const ShellyExplanationPage: FC = () => {
  const { t } = useTranslation();
  const { selectedHouse } = useSelectedHouse();
  const { isPending, integrationsList } = useIntegrations();
  const { openIntegrationUrl } = useOpenIntegrationUrl();
  const navigate = useNavigate();

  const shellyIntegration = integrationsList.find(
    (integration) => integration.id === IntegrationId.Shelly,
  );

  if (!selectedHouse || isPending) {
    return <LoadingIndicator fullScreen="overlay" />;
  }

  if (!shellyIntegration) {
    return <NotFoundPage />;
  }

  if (shellyIntegration.isConnected) {
    return (
      <Navigate
        to={`/houses/${selectedHouse?.houseId}/smart-devices`}
        replace
      />
    );
  }

  return (
    <Page navbarLayoutContentMobileBackground="white">
      <PageHeader
        backButton
        title={t('integrations.shelly.headerTitle') as string}
        withHousePicker
      />
      <Wrapper>
        <div className="mt-8">
          <div className="flex justify-center items-center gap-4 mb-8">
            <Icon name="Clevergy" size={50} />
            <FontIcon name="link" />
            <Icon name="Shelly" size={40} />
          </div>

          <div className="flex flex-col gap-4 text-center">
            <p className="text-xl font-semibold">
              {t('integrations.shelly.title')}
            </p>

            <div className="text-sm">
              <p>{t('integrations.shelly.subtitle')}</p>
              <div className="flex flex-col gap-2 my-4">
                <p>{t('integrations.shelly.instructions.part1')}</p>
                <p>{t('integrations.shelly.instructions.part2')}</p>
              </div>
              <p>
                <Trans
                  t={t}
                  values={{
                    address:
                      selectedHouse?.address ||
                      t('integrations.shelly.noAddress'),
                  }}
                >
                  integrations.shelly.instructions.part3
                </Trans>
              </p>
            </div>

            <div className="mt-4">
              <Button
                fullWidth
                onClick={() =>
                  openIntegrationUrl({
                    integrationUrl: shellyIntegration?.urlForConnect,
                    onFinished: () => {
                      navigate(
                        `/houses/${selectedHouse?.houseId}/smart-devices`,
                      );
                    },
                  })
                }
              >
                {t('integrations.shelly.buttons.connect')}
              </Button>
            </div>
          </div>
        </div>
      </Wrapper>
    </Page>
  );
};
