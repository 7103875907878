/* tslint:disable */

/* eslint-disable */

/**
 * Clevergy Portal Routes
 * Clevergy Portal Routes
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 * Comment information
 * @export
 * @interface Comment
 */
export interface Comment {
  /**
   * Comment identifier
   * @type {string}
   * @memberof Comment
   */
  id?: string;
  /**
   * Comment message
   * @type {string}
   * @memberof Comment
   */
  message?: string;
  /**
   * Comment author
   * @type {string}
   * @memberof Comment
   */
  author?: CommentAuthorEnum;
  /**
   * Comment creation date
   * @type {Date}
   * @memberof Comment
   */
  created?: Date;
  /**
   * Comment last update date
   * @type {Date}
   * @memberof Comment
   */
  updated?: Date;
}

/**
 * @export
 */
export const CommentAuthorEnum = {
  User: 'USER',
  Support: 'SUPPORT',
} as const;
export type CommentAuthorEnum =
  (typeof CommentAuthorEnum)[keyof typeof CommentAuthorEnum];

/**
 * Check if a given object implements the Comment interface.
 */
export function instanceOfComment(value: object): value is Comment {
  return true;
}

export function CommentFromJSON(json: any): Comment {
  return CommentFromJSONTyped(json, false);
}

export function CommentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Comment {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'] == null ? undefined : json['id'],
    message: json['message'] == null ? undefined : json['message'],
    author: json['author'] == null ? undefined : json['author'],
    created: json['created'] == null ? undefined : new Date(json['created']),
    updated: json['updated'] == null ? undefined : new Date(json['updated']),
  };
}

export function CommentToJSON(json: any): Comment {
  return CommentToJSONTyped(json, false);
}

export function CommentToJSONTyped(
  value?: Comment | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value['id'],
    message: value['message'],
    author: value['author'],
    created:
      value['created'] == null ? undefined : value['created'].toISOString(),
    updated:
      value['updated'] == null ? undefined : value['updated'].toISOString(),
  };
}
