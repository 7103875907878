import { Button } from '@clevergy/ui/components/Button';
import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { ModuleCard } from '@clevergy/ui/components/ModuleCard';
import { Link } from 'react-router-dom';

export const LienzoBanner = () => {
  return (
    <ModuleCard>
      <ModuleCard.Header
        title="Únete a niba"
        subtitle="Energía responsable con el medio ambiente, transparentes, y con las mejores condiciones para ti"
      />
      <ModuleCard.Content>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <div className="flex gap-1">
              <div className="text-clevergy-primary">
                <FontIcon name="energy" />
              </div>
              100% energía verde
            </div>
            <div className="flex gap-1">
              <div className="text-clevergy-primary">
                <FontIcon name="lock_open_right" />
              </div>
              Sin permanencia
            </div>
            <div className="flex gap-1">
              <div className="text-clevergy-primary">
                <FontIcon name="approval_delegation" />
              </div>
              Las mejores condiciones
            </div>
          </div>
          <Link to="/create-house-contract">
            <Button variant="filled" fullWidth>
              Ver tarifas
            </Button>
          </Link>
        </div>
      </ModuleCard.Content>
    </ModuleCard>
  );
};
