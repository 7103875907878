import { Heading } from '@clevergy/ui/components/Heading';
import { ProcessIconWaiting } from '@clevergy/ui/components/ProcessIconWaiting';
import { FC, ReactNode } from 'react';

export type WaitingScreenProps = {
  title: ReactNode;
  description: ReactNode;
};

export const WaitingScreen: FC<WaitingScreenProps> = (props) => {
  const { title, description } = props;

  return (
    <div className="flex flex-col gap-4 h-full">
      <div className="grow flex flex-col text-center items-center justify-center px-8 gap-4">
        <ProcessIconWaiting />

        <div>
          <Heading as="h2" colorScheme="neutral">
            {title}
          </Heading>
          <>{description}</>
        </div>
      </div>
    </div>
  );
};
