import { useHouseSettingsBackupStore } from '@clevergy/shared/stores/houseSettingsBackupStore';
import { useAuthContext } from 'context/AuthContext';
import { FC } from 'react';
import { useNavigate } from 'react-router';
import { useMount } from 'react-use';

export const LogoutPage: FC = () => {
  const navigate = useNavigate();
  const { signOut } = useAuthContext();

  const resetHouseSettingsBackup = useHouseSettingsBackupStore(
    (state) => state.reset,
  );

  useMount(() => {
    const signOutCaller = async () => {
      await signOut();
      resetHouseSettingsBackup();
      navigate('/');
    };
    signOutCaller();
  });

  return <></>;
};
