import { useClevergyEvent } from '@clevergy/event-bus';
import { ClevergyEventTypes } from '@clevergy/event-bus';
import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { useQueryClient } from '@tanstack/react-query';
import { useAuthContext } from 'context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useAppConfigurationStore } from 'stores/appConfigurationStore';

export const CreateHouseContractPage = () => {
  const { token } = useAuthContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { authedUser } = useAuthContext();
  const { setHasSkippedCreateHouseContract } = useAppConfigurationStore();

  useClevergyEvent(ClevergyEventTypes.CREATE_HOUSE_CONTRACT_SKIPPED, () => {
    setHasSkippedCreateHouseContract(true);
    navigate('/', { replace: true });
  });

  useClevergyEvent(ClevergyEventTypes.CREATE_HOUSE_CONTRACT_COMPLETED, () => {
    setHasSkippedCreateHouseContract(true);
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.GET_USER_SETUP, { userId: authedUser?.uid }],
    });
    navigate('/', { replace: true });
  });

  if (!token) {
    return null;
  }

  return <clevergy-create-house-contract data-token={token} />;
};
