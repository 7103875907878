/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface ConnectWithShellyCallbackRequest
 */
export interface ConnectWithShellyCallbackRequest {
  /**
   *
   * @type {number}
   * @memberof ConnectWithShellyCallbackRequest
   */
  userId?: number;
  /**
   *
   * @type {string}
   * @memberof ConnectWithShellyCallbackRequest
   */
  deviceCode?: string;
  /**
   *
   * @type {string}
   * @memberof ConnectWithShellyCallbackRequest
   */
  deviceId?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ConnectWithShellyCallbackRequest
   */
  name?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ConnectWithShellyCallbackRequest
   */
  host?: string;
  /**
   *
   * @type {string}
   * @memberof ConnectWithShellyCallbackRequest
   */
  deviceType?: string;
  /**
   *
   * @type {string}
   * @memberof ConnectWithShellyCallbackRequest
   */
  action?: string;
}

/**
 * Check if a given object implements the ConnectWithShellyCallbackRequest interface.
 */
export function instanceOfConnectWithShellyCallbackRequest(
  value: object,
): value is ConnectWithShellyCallbackRequest {
  return true;
}

export function ConnectWithShellyCallbackRequestFromJSON(
  json: any,
): ConnectWithShellyCallbackRequest {
  return ConnectWithShellyCallbackRequestFromJSONTyped(json, false);
}

export function ConnectWithShellyCallbackRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ConnectWithShellyCallbackRequest {
  if (json == null) {
    return json;
  }
  return {
    userId: json['userId'] == null ? undefined : json['userId'],
    deviceCode: json['deviceCode'] == null ? undefined : json['deviceCode'],
    deviceId: json['deviceId'] == null ? undefined : json['deviceId'],
    name: json['name'] == null ? undefined : json['name'],
    host: json['host'] == null ? undefined : json['host'],
    deviceType: json['deviceType'] == null ? undefined : json['deviceType'],
    action: json['action'] == null ? undefined : json['action'],
  };
}

export function ConnectWithShellyCallbackRequestToJSON(
  json: any,
): ConnectWithShellyCallbackRequest {
  return ConnectWithShellyCallbackRequestToJSONTyped(json, false);
}

export function ConnectWithShellyCallbackRequestToJSONTyped(
  value?: ConnectWithShellyCallbackRequest | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    userId: value['userId'],
    deviceCode: value['deviceCode'],
    deviceId: value['deviceId'],
    name: value['name'],
    host: value['host'],
    deviceType: value['deviceType'],
    action: value['action'],
  };
}
