/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface InvoiceAnalysis
 */
export interface InvoiceAnalysis {
  /**
   *
   * @type {string}
   * @memberof InvoiceAnalysis
   */
  dni?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceAnalysis
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceAnalysis
   */
  surname?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceAnalysis
   */
  houseCity?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceAnalysis
   */
  houseStreet?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceAnalysis
   */
  houseNumber?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceAnalysis
   */
  houseProvince?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceAnalysis
   */
  postalCode?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceAnalysis
   */
  cups?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceAnalysis
   */
  marketer?: string;
  /**
   *
   * @type {number}
   * @memberof InvoiceAnalysis
   */
  contractedPowerP1?: number;
  /**
   *
   * @type {number}
   * @memberof InvoiceAnalysis
   */
  contractedPowerP2?: number;
  /**
   *
   * @type {number}
   * @memberof InvoiceAnalysis
   */
  maxPowerDemandedP1?: number;
  /**
   *
   * @type {number}
   * @memberof InvoiceAnalysis
   */
  maxPowerDemandedP2?: number;
  /**
   *
   * @type {number}
   * @memberof InvoiceAnalysis
   */
  energyConsumptionP1?: number;
  /**
   *
   * @type {number}
   * @memberof InvoiceAnalysis
   */
  energyConsumptionP2?: number;
  /**
   *
   * @type {number}
   * @memberof InvoiceAnalysis
   */
  energyConsumptionP3?: number;
  /**
   *
   * @type {number}
   * @memberof InvoiceAnalysis
   */
  totalEnergyConsumption?: number;
  /**
   *
   * @type {number}
   * @memberof InvoiceAnalysis
   */
  totalCost?: number;
}

/**
 * Check if a given object implements the InvoiceAnalysis interface.
 */
export function instanceOfInvoiceAnalysis(
  value: object,
): value is InvoiceAnalysis {
  return true;
}

export function InvoiceAnalysisFromJSON(json: any): InvoiceAnalysis {
  return InvoiceAnalysisFromJSONTyped(json, false);
}

export function InvoiceAnalysisFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): InvoiceAnalysis {
  if (json == null) {
    return json;
  }
  return {
    dni: json['dni'] == null ? undefined : json['dni'],
    name: json['name'] == null ? undefined : json['name'],
    surname: json['surname'] == null ? undefined : json['surname'],
    houseCity: json['houseCity'] == null ? undefined : json['houseCity'],
    houseStreet: json['houseStreet'] == null ? undefined : json['houseStreet'],
    houseNumber: json['houseNumber'] == null ? undefined : json['houseNumber'],
    houseProvince:
      json['houseProvince'] == null ? undefined : json['houseProvince'],
    postalCode: json['postalCode'] == null ? undefined : json['postalCode'],
    cups: json['cups'] == null ? undefined : json['cups'],
    marketer: json['marketer'] == null ? undefined : json['marketer'],
    contractedPowerP1:
      json['contractedPowerP1'] == null ? undefined : json['contractedPowerP1'],
    contractedPowerP2:
      json['contractedPowerP2'] == null ? undefined : json['contractedPowerP2'],
    maxPowerDemandedP1:
      json['maxPowerDemandedP1'] == null
        ? undefined
        : json['maxPowerDemandedP1'],
    maxPowerDemandedP2:
      json['maxPowerDemandedP2'] == null
        ? undefined
        : json['maxPowerDemandedP2'],
    energyConsumptionP1:
      json['energyConsumptionP1'] == null
        ? undefined
        : json['energyConsumptionP1'],
    energyConsumptionP2:
      json['energyConsumptionP2'] == null
        ? undefined
        : json['energyConsumptionP2'],
    energyConsumptionP3:
      json['energyConsumptionP3'] == null
        ? undefined
        : json['energyConsumptionP3'],
    totalEnergyConsumption:
      json['totalEnergyConsumption'] == null
        ? undefined
        : json['totalEnergyConsumption'],
    totalCost: json['totalCost'] == null ? undefined : json['totalCost'],
  };
}

export function InvoiceAnalysisToJSON(json: any): InvoiceAnalysis {
  return InvoiceAnalysisToJSONTyped(json, false);
}

export function InvoiceAnalysisToJSONTyped(
  value?: InvoiceAnalysis | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    dni: value['dni'],
    name: value['name'],
    surname: value['surname'],
    houseCity: value['houseCity'],
    houseStreet: value['houseStreet'],
    houseNumber: value['houseNumber'],
    houseProvince: value['houseProvince'],
    postalCode: value['postalCode'],
    cups: value['cups'],
    marketer: value['marketer'],
    contractedPowerP1: value['contractedPowerP1'],
    contractedPowerP2: value['contractedPowerP2'],
    maxPowerDemandedP1: value['maxPowerDemandedP1'],
    maxPowerDemandedP2: value['maxPowerDemandedP2'],
    energyConsumptionP1: value['energyConsumptionP1'],
    energyConsumptionP2: value['energyConsumptionP2'],
    energyConsumptionP3: value['energyConsumptionP3'],
    totalEnergyConsumption: value['totalEnergyConsumption'],
    totalCost: value['totalCost'],
  };
}
