import { FailedScreen } from './components/FailedScreen';
import { SuccessScreen } from './components/SuccessScreen';
import { WaitingScreen } from './components/WaitingScreen';
import { GetOauthLoginUrlVendorEnum } from '@clevergy/api-client';
import { useMutation } from '@tanstack/react-query';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { Wrapper } from 'components/Wrapper';
import { useApiContext } from 'context/ApiContext';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const OauthHuaweiPage = () => {
  const { selectedHouse } = useSelectedHouse();
  const [searchParams] = useSearchParams();
  const { api } = useApiContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isSavingCodeDone = useRef(false);
  const hasErrorsSavingCode = useRef(false);

  const codeFromUrl = searchParams.get('code');
  const errorFromUrl = searchParams.get('error');

  const connectHouseWithOauthIntegrationMutation = useMutation({
    mutationFn: (payload: { houseId: string; code: string }) =>
      api.integrations.connectHouseWithOauthIntegration({
        houseId: payload.houseId,
        createOauthIntegrationRequest: {
          vendor: GetOauthLoginUrlVendorEnum.HuaweiOauth,
          code: payload.code,
        },
      }),
  });

  const hasErrors = errorFromUrl !== null || hasErrorsSavingCode.current;

  useEffect(() => {
    if (
      codeFromUrl &&
      selectedHouse?.houseId &&
      !connectHouseWithOauthIntegrationMutation.isPending &&
      !isSavingCodeDone.current
    ) {
      connectHouseWithOauthIntegrationMutation
        .mutateAsync({
          houseId: selectedHouse.houseId,
          code: codeFromUrl,
        })
        .then(() => {
          isSavingCodeDone.current = true;
        })
        .catch(() => {
          hasErrorsSavingCode.current = true;
        });
    }
  }, [
    codeFromUrl,
    connectHouseWithOauthIntegrationMutation,
    selectedHouse?.houseId,
  ]);

  if (!selectedHouse || connectHouseWithOauthIntegrationMutation.isPending) {
    return (
      <Page navbarLayoutContentMobileBackground="white">
        <PageHeader title={t('oauth.huawei.title') as string} />
        <Wrapper>
          <WaitingScreen
            title={t('oauth.huawei.waitingScreen.title') as string}
            description={t('oauth.huawei.waitingScreen.description') as string}
          />
        </Wrapper>
      </Page>
    );
  }

  if (hasErrors) {
    return (
      <Page navbarLayoutContentMobileBackground="white">
        <PageHeader
          title={t('oauth.huawei.title') as string}
          hideTitleAndBackButtonForNonMobile
        />
        <Wrapper>
          <FailedScreen
            title={t('oauth.huawei.failedScreen.title') as string}
            description={t('oauth.huawei.failedScreen.description') as string}
            onRetry={() =>
              navigate(`/houses/${selectedHouse.houseId}/integrations`, {
                replace: true,
              })
            }
          />
        </Wrapper>
      </Page>
    );
  }

  return (
    <Page navbarLayoutContentMobileBackground="white">
      <PageHeader title={t('oauth.huawei.title') as string} />
      <Wrapper>
        <SuccessScreen
          title={t('oauth.huawei.successScreen.title') as string}
          description={t('oauth.huawei.successScreen.description') as string}
        />
      </Wrapper>
    </Page>
  );
};
