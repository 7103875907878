/* tslint:disable */

/* eslint-disable */

/**
 * Clevergy Portal Routes
 * Clevergy Portal Routes
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type { SendChangePasswordLink, SendLoginLink } from '../models/index';
import {
  SendChangePasswordLinkFromJSON,
  SendChangePasswordLinkToJSON,
  SendLoginLinkFromJSON,
  SendLoginLinkToJSON,
} from '../models/index';
import * as runtime from '../runtime';

export interface SendChangePasswordLinkRequest {
  body?: SendChangePasswordLink;
}

export interface SendLoginLinkRequest {
  body?: SendLoginLink;
}

/**
 *
 */
export class AuthApi extends runtime.BaseAPI {
  /**
   * Send a change password link to the user email
   */
  async sendChangePasswordLinkRaw(
    requestParameters: SendChangePasswordLinkRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/consumer/auth/change-password`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: SendChangePasswordLinkToJSON(requestParameters['body']),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Send a change password link to the user email
   */
  async sendChangePasswordLink(
    requestParameters: SendChangePasswordLinkRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.sendChangePasswordLinkRaw(requestParameters, initOverrides);
  }

  /**
   * Send a login link to the user email
   */
  async sendLoginLinkRaw(
    requestParameters: SendLoginLinkRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/consumer/auth/login-with-link`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: SendLoginLinkToJSON(requestParameters['body']),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Send a login link to the user email
   */
  async sendLoginLink(
    requestParameters: SendLoginLinkRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.sendLoginLinkRaw(requestParameters, initOverrides);
  }
}
