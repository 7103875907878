import { Button } from '@clevergy/ui/components/Button';
import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { Heading } from '@clevergy/ui/components/Heading';
import { LoadingIndicator } from '@clevergy/ui/components/LoadingIndicator';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

export const NoConnectedState: FC = () => {
  const { t } = useTranslation();
  const { selectedHouse } = useSelectedHouse();
  const navigate = useNavigate();

  if (!selectedHouse) {
    return <LoadingIndicator fullScreen="overlay" />;
  }

  return (
    <div className="flex h-full flex-col justify-between">
      <div className="flex flex-col gap-6 p-5  text-center inset-0 z-50 m-auto items-center justify-center">
        <FontIcon name="help" size={64} />
        <Heading as="h3" centered colorScheme="neutral">
          {t('smartDevices.noConnectedState.title')}
        </Heading>
        <p>{t('smartDevices.noConnectedState.text')}</p>
      </div>
      <Button
        fullWidth
        onClick={() =>
          navigate(`/houses/${selectedHouse.houseId}/integrations/shelly`)
        }
      >
        {t('smartDevices.noConnectedState.button')}
      </Button>
    </div>
  );
};
