import { FontIcon } from '../FontIcon';
import { FC } from 'react';

export const ProcessIconFailed: FC = () => {
  return (
    <div className="text-red-600">
      <FontIcon name="close" size={56} circleBackgroundClass="bg-red-50" />
    </div>
  );
};
