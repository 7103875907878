import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const initialState = {
  tenantId: undefined,
  isOnboardingLessonsCompleted: false,
  hasAnsweredNotifications: false,
  hasSkippedCreateHouseContract: false,
};

export type AppConfigurationState = {
  tenantId: string | undefined;
  isOnboardingLessonsCompleted: boolean;
  hasAnsweredNotifications: boolean;
  hasSkippedCreateHouseContract: boolean;
};

export type AppConfigurationActions = {
  setTenantId: (tenantId: string | undefined) => void;
  setIsOnboardingLessonsAsCompleted: () => void;
  setHasAnsweredNotifications: (newState: boolean) => void;
  setHasSkippedCreateHouseContract: (newState: boolean) => void;
  reset: () => void;
};

export type AppConfigurationStore = AppConfigurationState &
  AppConfigurationActions;

export const useAppConfigurationStore = create<AppConfigurationStore>()(
  persist(
    (set) => ({
      ...initialState,
      setTenantId: (tenantId) => set({ tenantId }),
      setIsOnboardingLessonsAsCompleted: () =>
        set({ isOnboardingLessonsCompleted: true }),
      setHasAnsweredNotifications: (newState: boolean) =>
        set({ hasAnsweredNotifications: newState }),
      setHasSkippedCreateHouseContract: (newState: boolean) =>
        set({ hasSkippedCreateHouseContract: newState }),
      reset: () =>
        set({
          ...initialState,
        }),
    }),
    {
      name: 'appConfigurationStoreState',
      version: 6,
      // Migrations
      migrate(persistedState, version) {
        // Migrate from version 0 to 1 by adding hasAnsweredNotifications to true
        if (version === 0) {
          return {
            ...((persistedState as AppConfigurationStore) || initialState),
            hasAnsweredNotifications: true,
          } as AppConfigurationStore;
        }
        // Migrate from version 1 to 2 by adding hasSkippedSmartMeterIntegration
        // to version 1 hasSkippedRegister or false
        if (version === 2) {
          return {
            ...((persistedState as AppConfigurationStore) || initialState),
            hasSkippedSmartMeterIntegration: false,
          };
        }
        if (version === 3) {
          return {
            ...((persistedState as AppConfigurationStore) || initialState),
            hasAnsweredHouseSettingsWhileOnBoarding: false,
          };
        }
        if (version === 4) {
          // remove hasSkippedSmartMeterIntegration and hasAnsweredHouseSettingsWhileOnBoarding
          const {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            hasSkippedSmartMeterIntegration,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            hasAnsweredHouseSettingsWhileOnBoarding,
            ...cleanState
          } = persistedState as AppConfigurationStore & {
            hasSkippedSmartMeterIntegration: boolean;
            hasAnsweredHouseSettingsWhileOnBoarding: boolean;
          };
          return cleanState;
        }
        if (version === 5) {
          return {
            ...((persistedState as AppConfigurationStore) || initialState),
            hasSkippedCreateHouseContract: false,
          };
        }
        return (persistedState as AppConfigurationStore) || initialState;
      },
    },
  ),
);
