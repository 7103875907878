import { TicketComment } from './components/TicketComment';
import { TicketDescription } from './components/TicketDescription';
import { TicketFixed } from './components/TicketFixed';
import { TicketNotFound } from './components/TicketNotFound';
import { TicketRespond } from './components/TicketRespond';
import { TicketsResponseStatusEnum } from '@clevergy/api-client';
import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { LoadingIndicator } from '@clevergy/ui/components/LoadingIndicator';
import { skipToken, useQuery } from '@tanstack/react-query';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { PullToRefresh } from 'components/PullToRefresh';
import { Wrapper } from 'components/Wrapper';
import { useApiContext } from 'context/ApiContext';
import { useAuthContext } from 'context/AuthContext';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const THIRTY_SECONDS = 30 * 1000;

export const SupportTicketPage: FC = () => {
  const { t } = useTranslation();
  const { api } = useApiContext();
  const { ticketId } = useParams();
  const { authedUser } = useAuthContext();

  const ticketQuery = useQuery({
    queryKey: [
      QueryKeys.GET_TICKET,
      {
        ticketId,
      },
    ],
    queryFn:
      ticketId && authedUser?.uid
        ? () =>
            api.support.getTicket({
              ticketId,
              userId: authedUser.uid,
            })
        : skipToken,
    refetchInterval: THIRTY_SECONDS,
    staleTime: 0,
    refetchOnMount: true,
  });

  return (
    <Page navbarLayoutContentMobileBackground="white">
      <PageHeader
        backButton
        backButtonPath="/support"
        title={t('support.yourTicket.title') as string}
      />
      <PullToRefresh />
      <Wrapper>
        {ticketQuery.isPending && <LoadingIndicator fullScreen="transparent" />}

        {!ticketQuery.isPending && !ticketQuery.data && <TicketNotFound />}

        {!ticketQuery.isPending && ticketQuery.data?.ticketsResponse && (
          <div className="flex h-full flex-col gap-6">
            <div className="flex grow flex-col gap-2">
              <h2 className="font-semibold">
                {ticketQuery.data.ticketsResponse.title}
              </h2>

              <TicketDescription ticket={ticketQuery.data.ticketsResponse} />

              {ticketQuery.data.commentsResponse?.comments?.map((comment) => (
                <TicketComment key={comment.id} comment={comment} />
              ))}

              {(ticketQuery.data.ticketsResponse.status ===
                TicketsResponseStatusEnum.New ||
                ticketQuery.data.ticketsResponse.status ===
                  TicketsResponseStatusEnum.Open) && (
                <div className="text-center text-gray-700 pt-4">
                  {t('support.yourTicket.advice')}
                </div>
              )}
            </div>

            {ticketQuery.data.ticketsResponse.status ===
              TicketsResponseStatusEnum.Solved && (
              <TicketFixed ticketId={ticketQuery.data.ticketsResponse.id} />
            )}

            {ticketQuery.data.ticketsResponse.status ===
              TicketsResponseStatusEnum.Pending && (
              <TicketRespond ticketId={ticketQuery.data.ticketsResponse.id} />
            )}
          </div>
        )}
      </Wrapper>
    </Page>
  );
};
