/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface AnalyzedInvoice
 */
export interface AnalyzedInvoice {
  /**
   *
   * @type {string}
   * @memberof AnalyzedInvoice
   */
  invoiceAnalysisId: string;
}

/**
 * Check if a given object implements the AnalyzedInvoice interface.
 */
export function instanceOfAnalyzedInvoice(
  value: object,
): value is AnalyzedInvoice {
  if (
    !('invoiceAnalysisId' in value) ||
    value['invoiceAnalysisId'] === undefined
  )
    return false;
  return true;
}

export function AnalyzedInvoiceFromJSON(json: any): AnalyzedInvoice {
  return AnalyzedInvoiceFromJSONTyped(json, false);
}

export function AnalyzedInvoiceFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AnalyzedInvoice {
  if (json == null) {
    return json;
  }
  return {
    invoiceAnalysisId: json['invoiceAnalysisId'],
  };
}

export function AnalyzedInvoiceToJSON(json: any): AnalyzedInvoice {
  return AnalyzedInvoiceToJSONTyped(json, false);
}

export function AnalyzedInvoiceToJSONTyped(
  value?: AnalyzedInvoice | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    invoiceAnalysisId: value['invoiceAnalysisId'],
  };
}
