import { Outlet } from 'react-router';

export const IsolatedLayout = () => {
  return (
    <div className="flex flex-col min-h-full w-full items-center justify-center shrink-0">
      <div
        className="pb-safe-or-0 bg-clevergy-moduleContainerBackground flex flex-col h-full w-full sm:overflow-hidden sm:my-moduleContainerPadding sm:shrink-0 sm:min-h-[600px] sm:h-auto sm:max-w-md
       sm:shadow-moduleContainer sm:rounded-moduleContainer"
      >
        <Outlet />
      </div>
    </div>
  );
};
