/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 * response ticket
 * @export
 * @interface TicketsResponse
 */
export interface TicketsResponse {
  /**
   *
   * @type {string}
   * @memberof TicketsResponse
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof TicketsResponse
   */
  tenantId: string;
  /**
   *
   * @type {string}
   * @memberof TicketsResponse
   */
  userId?: string;
  /**
   *
   * @type {string}
   * @memberof TicketsResponse
   */
  houseId?: string;
  /**
   *
   * @type {string}
   * @memberof TicketsResponse
   */
  equipmentId?: string;
  /**
   *
   * @type {string}
   * @memberof TicketsResponse
   */
  alertId?: string;
  /**
   *
   * @type {string}
   * @memberof TicketsResponse
   */
  status: TicketsResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof TicketsResponse
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof TicketsResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof TicketsResponse
   */
  origin: string;
  /**
   *
   * @type {string}
   * @memberof TicketsResponse
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof TicketsResponse
   */
  updatedAt: string;
}

/**
 * @export
 */
export const TicketsResponseStatusEnum = {
  New: 'NEW',
  Open: 'OPEN',
  Pending: 'PENDING',
  Solved: 'SOLVED',
  Closed: 'CLOSED',
} as const;
export type TicketsResponseStatusEnum =
  (typeof TicketsResponseStatusEnum)[keyof typeof TicketsResponseStatusEnum];

/**
 * Check if a given object implements the TicketsResponse interface.
 */
export function instanceOfTicketsResponse(
  value: object,
): value is TicketsResponse {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('tenantId' in value) || value['tenantId'] === undefined) return false;
  if (!('status' in value) || value['status'] === undefined) return false;
  if (!('title' in value) || value['title'] === undefined) return false;
  if (!('origin' in value) || value['origin'] === undefined) return false;
  if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
  if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
  return true;
}

export function TicketsResponseFromJSON(json: any): TicketsResponse {
  return TicketsResponseFromJSONTyped(json, false);
}

export function TicketsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TicketsResponse {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    tenantId: json['tenantId'],
    userId: json['userId'] == null ? undefined : json['userId'],
    houseId: json['houseId'] == null ? undefined : json['houseId'],
    equipmentId: json['equipmentId'] == null ? undefined : json['equipmentId'],
    alertId: json['alertId'] == null ? undefined : json['alertId'],
    status: json['status'],
    title: json['title'],
    description: json['description'] == null ? undefined : json['description'],
    origin: json['origin'],
    createdAt: json['createdAt'],
    updatedAt: json['updatedAt'],
  };
}

export function TicketsResponseToJSON(json: any): TicketsResponse {
  return TicketsResponseToJSONTyped(json, false);
}

export function TicketsResponseToJSONTyped(
  value?: TicketsResponse | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value['id'],
    tenantId: value['tenantId'],
    userId: value['userId'],
    houseId: value['houseId'],
    equipmentId: value['equipmentId'],
    alertId: value['alertId'],
    status: value['status'],
    title: value['title'],
    description: value['description'],
    origin: value['origin'],
    createdAt: value['createdAt'],
    updatedAt: value['updatedAt'],
  };
}
