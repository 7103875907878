/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface CommentRequest
 */
export interface CommentRequest {
  /**
   *
   * @type {string}
   * @memberof CommentRequest
   */
  tenantId: string;
  /**
   *
   * @type {string}
   * @memberof CommentRequest
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof CommentRequest
   */
  author?: CommentRequestAuthorEnum;
}

/**
 * @export
 */
export const CommentRequestAuthorEnum = {
  User: 'USER',
  Support: 'SUPPORT',
} as const;
export type CommentRequestAuthorEnum =
  (typeof CommentRequestAuthorEnum)[keyof typeof CommentRequestAuthorEnum];

/**
 * Check if a given object implements the CommentRequest interface.
 */
export function instanceOfCommentRequest(
  value: object,
): value is CommentRequest {
  if (!('tenantId' in value) || value['tenantId'] === undefined) return false;
  if (!('message' in value) || value['message'] === undefined) return false;
  return true;
}

export function CommentRequestFromJSON(json: any): CommentRequest {
  return CommentRequestFromJSONTyped(json, false);
}

export function CommentRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CommentRequest {
  if (json == null) {
    return json;
  }
  return {
    tenantId: json['tenantId'],
    message: json['message'],
    author: json['author'] == null ? undefined : json['author'],
  };
}

export function CommentRequestToJSON(json: any): CommentRequest {
  return CommentRequestToJSONTyped(json, false);
}

export function CommentRequestToJSONTyped(
  value?: CommentRequest | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    tenantId: value['tenantId'],
    message: value['message'],
    author: value['author'],
  };
}
