import { Button } from '@clevergy/ui/components/Button';
import { Modal } from '@clevergy/ui/components/Modal';
import { useMutation } from '@tanstack/react-query';
import { useApiContext } from 'context/ApiContext';
import { useAuthContext } from 'context/AuthContext';
import { useTenantSettings } from 'context/TenantSettingsContext';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type ResetPasswordModalProps = {
  isOpen: boolean;
  onCloseModal: () => void;
};

export const ResetPasswordModal: FC<ResetPasswordModalProps> = ({
  isOpen,
  onCloseModal,
}) => {
  const { t } = useTranslation();

  const { api } = useApiContext();
  const { authedUser } = useAuthContext();
  const { tenantGcpId } = useTenantSettings();

  const [resetEmailSent, setResetEmailSent] = useState(false);

  const resetPasswordMutation = useMutation({
    mutationFn: () => {
      if (!authedUser?.email) {
        throw new Error('No email found');
      }
      return api.auth.sendChangePasswordLink({
        body: {
          email: authedUser.email,
          tenantGcpId: tenantGcpId,
        },
      });
    },
    onSuccess: () => {
      setResetEmailSent(true);
    },
  });

  return (
    <Modal isOpen={isOpen} onClose={() => onCloseModal()}>
      <div className="flex flex-col justify-center items-center gap-4 text-center h-full">
        {!resetEmailSent && (
          <>
            <p>{t('account.reset-password-modal.message-before')}</p>
            <Button
              fullWidth
              variant="filled"
              colorScheme="primary"
              onClick={() => resetPasswordMutation.mutate()}
              busy={resetPasswordMutation.isPending}
              disabled={resetPasswordMutation.isPending}
            >
              {t('account.reset-password-modal.confirm')}
            </Button>
          </>
        )}

        {resetEmailSent && (
          <>
            <p>{t('account.reset-password-modal.message-after')}</p>
            <Button fullWidth onClick={() => onCloseModal()}>
              {t('account.reset-password-modal.close')}
            </Button>
          </>
        )}
      </div>
    </Modal>
  );
};
