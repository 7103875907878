import {
  GetPreferencesNotificationsTypeEnum,
  UpdatePreferencesNotificationsRequest,
  UserPreferencesNotificationResponse,
} from '@clevergy/api-client';
import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { InputCheckToggleSwitch } from '@clevergy/ui/components/InputCheckToggleSwitch';
import {
  skipToken,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useApiContext } from 'context/ApiContext';
import { useAuthContext } from 'context/AuthContext';
import { FC } from 'react';

export type PreferencesNotificationToggleProps = {
  type: GetPreferencesNotificationsTypeEnum;
  title: string;
  description: string;
};

export const PreferencesNotificationToggle: FC<
  PreferencesNotificationToggleProps
> = (props) => {
  const { title, type, description } = props;
  const { authedUser } = useAuthContext();
  const { api } = useApiContext();
  const queryClient = useQueryClient();

  const queryKey = [
    QueryKeys.GET_USER_PREFERENCES_NOTIFICATIONS,
    {
      userId: authedUser?.uid,
      type,
    },
  ];

  const preferencesNotificationQuery = useQuery({
    queryKey,
    queryFn: authedUser?.uid
      ? () =>
          api.users.getPreferencesNotifications({
            userId: authedUser?.uid,
            type,
          })
      : skipToken,
  });

  const updatePreferencesNotificationMutation = useMutation({
    mutationFn: (payload: UpdatePreferencesNotificationsRequest) =>
      api.users.updatePreferencesNotifications(payload),
    onMutate: async (newState) => {
      await queryClient.cancelQueries({ queryKey });
      const previousState = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(
        queryKey,
        (old: UserPreferencesNotificationResponse) => ({
          ...old,
          push: newState.updateUserPreferencesNotifications?.push,
        }),
      );
      return { previousState };
    },
    onError: (err, newState, context) => {
      queryClient.setQueryData(queryKey, context?.previousState);
    },
  });

  const handleToggle = async (value: boolean) => {
    if (!authedUser?.uid) {
      return;
    }
    await updatePreferencesNotificationMutation.mutateAsync({
      userId: authedUser?.uid,
      type,
      updateUserPreferencesNotifications: {
        push: value,
      },
    });
    queryClient.invalidateQueries({
      queryKey,
    });
  };

  if (preferencesNotificationQuery.isPending) {
    return null;
  }

  return (
    <div className="border border-clevergy-border rounded-lg p-4">
      <div className="flex justify-between mb-1">
        <p>
          <strong>{title}</strong>
        </p>
        <div className="shrink-0">
          <InputCheckToggleSwitch
            id={`notification-toggle-${type}`}
            name={`notification-toggle-${type}`}
            label={title}
            hiddenLabel
            disabled={updatePreferencesNotificationMutation.isPending}
            value={type}
            isChecked={preferencesNotificationQuery.data?.push}
            onChange={(e) => handleToggle(e.target.checked)}
          />
        </div>
      </div>
      <p>{description}</p>
    </div>
  );
};
