/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 * Detail of contract
 * @export
 * @interface HouseContractDetail
 */
export interface HouseContractDetail {
  /**
   *
   * @type {string}
   * @memberof HouseContractDetail
   */
  cups?: string;
  /**
   *
   * @type {string}
   * @memberof HouseContractDetail
   */
  houseId?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof HouseContractDetail
   */
  contractedPower?: Array<number>;
  /**
   *
   * @type {Date}
   * @memberof HouseContractDetail
   */
  startDate?: Date;
  /**
   *
   * @type {Date}
   * @memberof HouseContractDetail
   */
  endDate?: Date;
  /**
   *
   * @type {Array<number>}
   * @memberof HouseContractDetail
   */
  powerPrices?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof HouseContractDetail
   */
  energyPrices?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof HouseContractDetail
   */
  provider?: string;
  /**
   *
   * @type {string}
   * @memberof HouseContractDetail
   */
  codefare?: string;
  /**
   *
   * @type {string}
   * @memberof HouseContractDetail
   */
  distributor?: string;
  /**
   *
   * @type {boolean}
   * @memberof HouseContractDetail
   */
  pvpc?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof HouseContractDetail
   */
  socialBonus?: boolean;
  /**
   *
   * @type {string}
   * @memberof HouseContractDetail
   */
  tariffType?: string;
  /**
   *
   * @type {string}
   * @memberof HouseContractDetail
   */
  tariffName?: string;
  /**
   *
   * @type {string}
   * @memberof HouseContractDetail
   */
  status?: string;
  /**
   *
   * @type {number}
   * @memberof HouseContractDetail
   */
  solarPrice?: number;
  /**
   *
   * @type {string}
   * @memberof HouseContractDetail
   */
  commercialName?: string;
  /**
   *
   * @type {string}
   * @memberof HouseContractDetail
   */
  holderName?: string;
  /**
   *
   * @type {string}
   * @memberof HouseContractDetail
   */
  iban?: string;
  /**
   *
   * @type {number}
   * @memberof HouseContractDetail
   */
  margin?: number;
}

/**
 * Check if a given object implements the HouseContractDetail interface.
 */
export function instanceOfHouseContractDetail(
  value: object,
): value is HouseContractDetail {
  return true;
}

export function HouseContractDetailFromJSON(json: any): HouseContractDetail {
  return HouseContractDetailFromJSONTyped(json, false);
}

export function HouseContractDetailFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): HouseContractDetail {
  if (json == null) {
    return json;
  }
  return {
    cups: json['cups'] == null ? undefined : json['cups'],
    houseId: json['houseId'] == null ? undefined : json['houseId'],
    contractedPower:
      json['contractedPower'] == null ? undefined : json['contractedPower'],
    startDate:
      json['startDate'] == null ? undefined : new Date(json['startDate']),
    endDate: json['endDate'] == null ? undefined : new Date(json['endDate']),
    powerPrices: json['powerPrices'] == null ? undefined : json['powerPrices'],
    energyPrices:
      json['energyPrices'] == null ? undefined : json['energyPrices'],
    provider: json['provider'] == null ? undefined : json['provider'],
    codefare: json['codefare'] == null ? undefined : json['codefare'],
    distributor: json['distributor'] == null ? undefined : json['distributor'],
    pvpc: json['pvpc'] == null ? undefined : json['pvpc'],
    socialBonus: json['socialBonus'] == null ? undefined : json['socialBonus'],
    tariffType: json['tariffType'] == null ? undefined : json['tariffType'],
    tariffName: json['tariffName'] == null ? undefined : json['tariffName'],
    status: json['status'] == null ? undefined : json['status'],
    solarPrice: json['solarPrice'] == null ? undefined : json['solarPrice'],
    commercialName:
      json['commercialName'] == null ? undefined : json['commercialName'],
    holderName: json['holderName'] == null ? undefined : json['holderName'],
    iban: json['iban'] == null ? undefined : json['iban'],
    margin: json['margin'] == null ? undefined : json['margin'],
  };
}

export function HouseContractDetailToJSON(json: any): HouseContractDetail {
  return HouseContractDetailToJSONTyped(json, false);
}

export function HouseContractDetailToJSONTyped(
  value?: HouseContractDetail | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    cups: value['cups'],
    houseId: value['houseId'],
    contractedPower: value['contractedPower'],
    startDate:
      value['startDate'] == null ? undefined : value['startDate'].toISOString(),
    endDate:
      value['endDate'] == null ? undefined : value['endDate'].toISOString(),
    powerPrices: value['powerPrices'],
    energyPrices: value['energyPrices'],
    provider: value['provider'],
    codefare: value['codefare'],
    distributor: value['distributor'],
    pvpc: value['pvpc'],
    socialBonus: value['socialBonus'],
    tariffType: value['tariffType'],
    tariffName: value['tariffName'],
    status: value['status'],
    solarPrice: value['solarPrice'],
    commercialName: value['commercialName'],
    holderName: value['holderName'],
    iban: value['iban'],
    margin: value['margin'],
  };
}
